import { gql } from '@apollo/client'

export const VerifyLabResultToken = gql`
  query VerifyLabResultToken($token: String!) {
    verifyLabResultToken(token: $token) {
      status
      expires_at
    }
  }
`

export const GetLabResultViaToken = gql`
  query GetLabResultViaToken(
    $labResultInput: LabResultInput!
    $testsInput: TestsInput
  ) {
    getLabResultViaToken(input: $labResultInput) {
      _id
      short_id
      name
      collection_date
      result_date
      created_at
      status
      patient_notes
      result_documents {
        order_short_id
        results_pdf_report {
          url
        }
      }
      summary {
        normal
        abnormal
        neutral
      }
      partner {
        name
      }

      provider {
        name
      }

      results {
        name
        notes
        details
        summary
        subtitle
        icon {
          url
        }
        tests(input: $testsInput) {
          _id
          name
          value
          units
          status
          indicator
          notes
          ref_range
          flag_statement
          aka
          external_references {
            ad_hoc {
              url
              title
            }
            wikipedia {
              url
              logo {
                url
              }
            }
            medline {
              url
              logo {
                url
              }
            }
          }
          result_summaries {
            what_is_this_test_used_for
            what_might_affect_test_results
            what_should_i_ask_my_clinician
            test_summaries {
              question
              answer
            }
          }
          test_history {
            ranges {
              max
              min
              type
              __typename
            }
            results {
              date
              tooltip
              value
              type
              __typename
            }
            units
          }
        }
      }
    }
  }
`
