import React, { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import classnames from 'classnames'
import { Form } from '@labsavvyapp/ui-components'
import style from './SignUpPage.module.css'
import ButtonArrowRight from '../../../components/buttons/ButtonArrowRight'
import CheckboxRound from '../../../components/checkbox/CheckboxRound'
import { useHistory } from 'react-router'
import { SIGN } from '../../../routes/config'
import useLocalReducer from '../../../hooks/useLocalReducer'

function HelloScreen() {
  const { push } = useHistory()
  const [checked, setChecked] = useState<boolean>(false)
  const [
    state,
    // etState,
    // clearState
  ] = useLocalReducer('partner_data', (state: any, action: any) => ({
    ...state,
    ...action,
  }))

  const onSubmit = () => {
    push(SIGN.upEmail)
  }

  return (
    <div className={style.root}>
      <div className={style.formContainer}>
        <Form>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {state.partner_data?.project_logo?.url && (
                  <img src={`${state.partner_data.project_logo.url}`}></img>
                )}
                <h2 className={classnames(style.text, style.title)}>
                  Welcome.
                </h2>
                <p className={classnames(style.text, style.description)}>
                  Congratulations on taking this first step on your wellness
                  journey.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <CheckboxRound
                  handleChange={(value: boolean) => setChecked(value)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className={style.actions}>
                  <ButtonArrowRight
                    onClick={onSubmit}
                    disabled={!checked}
                    text={'Get Started'}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    </div>
  )
}

export default HelloScreen
