import { useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/messaging'
import { useMutation } from '@apollo/client'
import { isSafari } from '../utils/browser'
import { StoreRegistrationToken } from '../graphql/messaging/mutations'

function getFirebase(firebaseConfig) {
  if (!firebase.apps.length) return firebase.initializeApp(firebaseConfig)
  // it's already initialized
  else return firebase.app()
}

export const useFirebase = (firebaseConfig) => {
  const firebaseApp = getFirebase(firebaseConfig)
  const messaging = firebaseApp.messaging()

  const [storeRegistrationToken] = useMutation(StoreRegistrationToken, {
    context: {
      clientName: 'messaging',
    },
  })

  return {
    messaging,
    isAvailable: process.env.REACT_APP_USE_CHAT === 'true' && !isSafari(),
    refreshToken: () => {
      return messaging
        .getToken({ vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY })
        .then((token) => {
          if (token) return storeRegistrationToken({ variables: { token } })
          else {
            console.error('Unable to get token')
            return Promise.resolve(Error('Unable to get token'))
          }
        })
    },
  }
}

export const useNotification = () => {
  const [hasPermission, setHasPermission] = useState(
    Notification.permission === 'granted',
  )

  return {
    hasPermission,
    requestPermission: () =>
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          setHasPermission(true)
        }
      }),
  }
}
