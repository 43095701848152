import React from 'react'

import formatterPrice from '../../../utils/formatter-price'

interface CheckoutItemListProps {
  name: string
  total_price: number
}

export default function CheckoutItemList({
  name,
  total_price,
}: CheckoutItemListProps) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '80%', fontSize: 14, fontWeight: 500 }}>{name}</div>
      <div style={{ width: '20%', textAlign: 'right' }}>
        {formatterPrice(total_price)}
      </div>
    </div>
  )
}
