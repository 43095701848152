import { useEffect, useState } from 'react'
import { Popup } from 'semantic-ui-react'
// import classnames from 'classnames'
import { useApolloClient } from '@apollo/client'
import { Image } from 'semantic-ui-react'

import {
  ErrorModal,
  Form,
  FormContext,
  Button,
} from '@labsavvyapp/ui-components'

import { useIntl } from 'react-intl'
// import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router'

import style from './SignUpPage.module.css'
import { SIGN } from '../../../routes/config'
// import { RegisterEmailUser } from '../../../graphql/user/mutations'
import ActionInputField from '../../../components/ActionInputField/ActionInputField'
import { GetPartnerInfoByPartnerCode } from '../../../graphql/connected-providers/queries'

// interface FormPreRegister {
//   provider: string
// }

// const initialState = {
//   partner_data: {},
// }

function ProviderConnectScreen() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const client = useApolloClient()
  // const [registerEmailUser] = useMutation(RegisterEmailUser)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage] = useState('error')
  const [loading, setLoading] = useState(false)

  const handleResetFormErrors = () => {
    // if (Object.keys(formErrors).length > 0) {
    //   setFormErrors({
    //     email: null,
    //     password: null,
    //   })
    // }
  }

  const formData = {
    providerCode: '',
  }

  const [formErrors, setFormErrors] = useState({})
  const [selectedPartner, setSelectedPartner] = useState()
  const [hipaaAuth, setHipaaAuth] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState('')
  async function handleFormSubmit(formData: any) {
    try {
      setLoading(true)
      localStorage.setItem('partnerCode', JSON.stringify(formData.providerCode))
      const { data } = await client.query({
        query: GetPartnerInfoByPartnerCode,
        variables: { code: formData.providerCode },
      })

      if (data.mobileGetPartnerInfoByPartnerCode) {
        setSelectedPartner(
          data.mobileGetPartnerInfoByPartnerCode &&
            data.mobileGetPartnerInfoByPartnerCode,
        )
      } else {
        setErrorMsg('Invalid Provider Code')
      }
    } catch (error) {
      setLoading(false)
      setFormErrors({ providerCode: 'invalid code' })
    }
  }

  const handleConnect = () => {
    if (!hipaaAuth) {
      return setShowErrorModal(true)
    } else {
      localStorage.setItem('selectedPartner', JSON.stringify(selectedPartner))

      push(SIGN.upEmail)
    }
    // return showModals({ partnerCode })
  }

  const cancelConnect = () => {
    localStorage.removeItem('selectedPartner')
    localStorage.removeItem('newUser')

    setErrorMsg('')
    setSelectedPartner(null)
  }

  useEffect(() => {
    const partnerData = JSON.parse(localStorage.getItem('selectedPartner'))
    if (partnerData) {
      setSelectedPartner(partnerData)
    }
  }, [])

  return (
    <div className={style.root}>
      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
      />
      <div className={style.formContainer}>
        <p className={style.stepNumber}>1/5</p>
        <p className={style.description}>Connect with your Provider.</p>

        {!selectedPartner ? (
          <>
            <p className={style.detail}>What’s your provider code?</p>

            <Form
              className={style.providerCodeInputFormContainer}
              initialFormData={formData}
              errors={formErrors}
              intl={{
                formatMessage,
              }}
              onSubmit={handleFormSubmit}
            >
              <FormContext.Consumer>
                {() => (
                  <ActionInputField
                    name="provider"
                    onChange={() => handleResetFormErrors()}
                    loading={loading}
                  />
                )}
              </FormContext.Consumer>
            </Form>

            {errorMsg && (
              <p style={{ textAlign: 'left', color: 'red', marginTop: 10 }}>
                {errorMsg}
              </p>
            )}

            <Popup
              trigger={
                <p
                  style={{
                    textAlign: 'left',
                    color: '#776FC3',
                    marginTop: 15,
                    cursor: 'pointer',
                  }}
                >
                  Where can I find this code?
                </p>
              }
              content="Your Provider Code can be found in the LabSavvy “Welcome” email sent to your inbox."
              position="bottom left"
            />
          </>
        ) : (
          <div className={style.providerDetails}>
            <Image
              src={selectedPartner?.project_logo?.url}
              className={style.photo}
            />
            <h3 className={style.providerName}>{selectedPartner.name} </h3>
            {/* <h4>
              Connect and import your current lab results from this LabSavvy
              partner and to allow for automatic retrieval of future lab results
              and health records.
            </h4> */}
            <h4 className={style.description}>
              Congratulations on taking this first step on your wellness
              journey.
            </h4>
            <div className={style.hipaaAgreement}>
              <input
                type="radio"
                checked={hipaaAuth}
                className={style.checkbox}
                onClick={() => setHipaaAuth(!hipaaAuth)}
              />
              I have read and accept our
              <a
                href={process.env.REACT_APP_HIPAA_AUTH_URL}
                target="_blank"
                rel="noreferrer"
              >
                {` HIPAA Authorization `}
              </a>
              and
              <a
                href={process.env.REACT_APP_TERMS_OF_SERVICE}
                target="_blank"
                rel="noreferrer"
              >
                {` Terms of Service `}
              </a>
            </div>
            <div>
              <Button
                className={style.connectButton}
                variant="primary"
                onClick={handleConnect}
              >
                SIGN UP
              </Button>
              <Button
                className={style.connectButton}
                variant="basic"
                onClick={cancelConnect}
              >
                CANCEL
              </Button>
            </div>
            <ErrorModal
              message={'Accept HIPAA Terms!'}
              onCloseClick={() => setShowErrorModal(false)}
              open={showErrorModal}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProviderConnectScreen
