import React, { useState } from 'react'
import style from './LabReportResultCategory.module.css'
import { Modal } from '@labsavvyapp/ui-components'

interface LabReportResultCategoryProps {
  children: any
  name: string
  subtitle: string
  summary: string
  image: string
  style: any
  details: string
}

export default function LabReportResultCategory({
  children,
  name,
  subtitle,
  summary,
  image,
  style: outStyle,
  details,
}: LabReportResultCategoryProps) {
  const [modalInfo, setModalInfo] = useState(false)

  return (
    <div className={style.container} style={outStyle}>
      <div className={style.category}>
        <h2 className={style.title}>{name}</h2>
        {image ? (
          <img alt="Icon" className={style.categoryImage} src={image} />
        ) : null}
      </div>
      <div className={style.summaryContainer}>
        {subtitle ? (
          <div className={style.subtitle}>{`${subtitle}`}</div>
        ) : null}
        {summary && (
          <div>
            <div
              data-test="lab-report-note"
              className={style.summary}
              dangerouslySetInnerHTML={{ __html: summary || '' }}
            />
          </div>
        )}
      </div>

      <div>{children}</div>

      <Modal onCloseClick={() => setModalInfo(false)} open={modalInfo}>
        <div
          data-test="lab-report-note"
          className={style.summary}
          dangerouslySetInnerHTML={{ __html: details || '' }}
        />
      </Modal>
    </div>
  )
}
