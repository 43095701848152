import { Fragment, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router'
import { Button } from '@labsavvyapp/ui-components'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Loader, Message } from 'semantic-ui-react'
import { ShopContext, Product } from '../../../components/context/ShopContext'
import styles from './Checkout.module.css'
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE,
  RETURNS_URL,
} from '../../../utils/externalLinks'
import {
  StartStoreCart,
  FinalizeCart,
} from '../../../graphql/ecommerce/mutation'
import { useSavingModals } from '@labsavvyapp/ui-components'
import CheckoutItemList from './CheckoutItemList'
import { STORE } from '../../../routes/config'
import LoadCardBaseInfo from './LoadCardBaseInfo'
import PlaceOrderButton, { Cart } from './PlaceOrderButton'
import BackButton from '../../../components/BackButton/BackButton'
import formatterPrice from '../../../utils/formatter-price'

const stripePromise: PromiseLike<Stripe | null> =
  (process.env.REACT_APP_USE_ECOMMERCE === 'true' &&
    loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '')) ||
  Promise.resolve(null)

export default function CheckoutPage({ me }: any) {
  const { push } = useHistory()
  const [startStoreCart, { data, loading }] = useMutation(StartStoreCart)
  const [finalizeCart] = useMutation(FinalizeCart)
  const shopContext = useContext(ShopContext)
  const productIds = Object.keys(shopContext.items)
  const totalPrice = shopContext.getTotalPrice()

  // cart items
  const storeItems = productIds.map((id) => ({
    quantity: shopContext.items[id].amount,
    product_id: shopContext.items[id].data._id,
    product_type: 'package',
  }))

  // setup card object
  const locCardState = {
    card: {
      id: '',
      brand: '',
      default: false,
      last4: '',
    },
    clientSecret: '',
    paymentIntentId: '',
  }

  if (data) {
    const { startStoreCart } = data
    locCardState.card = startStoreCart.default_payment_method
    locCardState.clientSecret = startStoreCart.client_secret
    locCardState.paymentIntentId = data.startStoreCart.payment_intent_id
  }

  // display modal while processing payment
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're processing your payment, please wait...",
    savedMessage: 'Your payment has been processed',
    callback: async (cart: Cart) => {
      await finalizeCart({
        variables: { cart },
      })
    },
    onSuccess: () => {
      shopContext.removeAll()
      push(STORE.checkoutCompleted)
    },
    onError: (error: any) => error,
  })

  const goToPayment = () => {
    push(`${STORE.payment}`)
  }

  useEffect(() => {
    startStoreCart({
      variables: {
        amount: totalPrice,
        items: storeItems,
      },
    })
  }, [])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <BackButton text="Back to Cart" to={STORE.bag} />
        <p className={styles.mainHeaderText}>Checkout</p>
      </div>

      <div className={styles.checkoutContainer}>
        <div className={styles.columnContainer}>
          <div className={styles.columnCard}>
            <div className={styles.columnCardTitle}>Your Email</div>
            <div
              style={{ margin: 10, padding: 10, border: '1px solid #e5e5e5' }}
            >
              {me?.emails[0].address}
            </div>
            <div style={{ paddingLeft: 15, fontSize: 12 }}>
              You`ll receive receipts and notifications of your order at this
              email address.
            </div>
          </div>
          <div className={styles.columnCard}>
            <div className={styles.columnCardTitle}>Payment</div>
            {loading ? (
              <Loader active size="large" inline="centered" />
            ) : (
              <>
                {locCardState.card ? (
                  <>
                    <LoadCardBaseInfo cardInfo={locCardState?.card} />
                    <div className={styles.centerContainer}>
                      <Button
                        className={styles.checkoutButton}
                        variant={Button.variant.basic}
                        onClick={goToPayment}
                      >
                        <h5 className={styles.paymentButtonLabel}>
                          Update Payment Method
                        </h5>
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Message>
                      <Message.Header>
                        Please update your payment details
                      </Message.Header>
                      <p>
                        It seems you haven`t added your payment information for
                        you to proceed with this order
                      </p>
                    </Message>
                    <div className={styles.centerContainer}>
                      <Button
                        variant={Button.variant.basic}
                        onClick={goToPayment}
                      >
                        <h5 className={styles.paymentButtonLabel}>
                          Add Payment Method
                        </h5>
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.columnContainer}>
          <div className={styles.columnCard}>
            <div className={styles.columnCardTitle}>Order Summary</div>
            {loading ? (
              <Loader active size="large" inline="centered" />
            ) : (
              <>
                {productIds.map((id) => {
                  const product: Product = shopContext.items[id].data
                  return (
                    <Fragment key={id}>
                      <CheckoutItemList
                        name={product.name}
                        total_price={product.total_price}
                      />
                      <div className={styles.itemDivider} />
                    </Fragment>
                  )
                })}
                <div className={styles.blackDividerTop} />
                <div className={styles.itemListContainer}>
                  <h2 className={styles.smallLabel}>
                    Subtotal ({productIds.length} items)
                  </h2>
                  <h3 className={styles.smallLabel}>
                    {formatterPrice(totalPrice)}
                  </h3>
                </div>
                <div className={styles.itemListContainer}>
                  <h2 className={styles.smallLabel}>Fees/Taxes</h2>
                  <h3 className={styles.smallLabel}>—</h3>
                </div>
                <div className={styles.spacer} />
                <div className={styles.itemListContainer}>
                  <h2 className={styles.boldLabel}>Total</h2>
                  <h3 className={styles.boldLabel}>
                    {formatterPrice(totalPrice)}
                  </h3>
                </div>
                <div className={styles.spacer} />
                <div className={styles.centerContainer}>
                  <Elements stripe={stripePromise}>
                    <PlaceOrderButton
                      card={locCardState?.card}
                      clientSecret={locCardState?.clientSecret}
                      paymentIntentId={locCardState?.paymentIntentId}
                      showModals={showModals}
                    />
                  </Elements>
                </div>
              </>
            )}
            <div className={styles.centerContainer}>
              <Link
                className={styles.linkButton}
                to={RETURNS_URL}
                target="_blank"
              >
                RETURN POLICY
              </Link>
              <Link
                className={styles.linkButton}
                to={PRIVACY_POLICY_URL}
                target="_blank"
              >
                PRIVACY POLICY
              </Link>
              <Link
                className={styles.linkButton}
                to={TERMS_OF_SERVICE}
                target="_blank"
              >
                TERMS OF USE
              </Link>
            </div>
          </div>
        </div>
      </div>

      {modals}
    </div>
  )
}
