import { useEffect } from 'react'
import { useQuery } from '@apollo/client'

import {
  NavLink,
  useParams,
  useHistory,
  generatePath,
  useLocation,
} from 'react-router-dom'
import { Loader } from '@labsavvyapp/ui-components'
import StickyBox from 'react-sticky-box'

// Components
import { Icon } from 'semantic-ui-react'
import { DetailsLayout } from '../../../layouts/DetailsLayout/DetailsLayout'
import { LabReportsResponse } from '../view/LabReportViewPage'
import TestReferenceFAQ, { ResultSummaries } from './TestReferenceFAQ'
import ReviewBoardApproved from './ReviewBoardApproved'
import ExternalReferences, {
  IExternalReferences,
} from './ExternalReferences/ExternalReferences'
import FlagStatement from './FlagStatement'
import { LabResultTestHistory } from './Charts/OneDimChartDetails'
import HistoryChart from './Charts/HistoryChart'
import BackButton from '../../../components/BackButton/BackButton'
import ReportDetails from '../../../components/orderdetails/ReportDetails'
import LabReportNotes from '../view/LabReportNotes'
import Panel, { PanelHeader, PanelBody } from '../../../components/Panel/Panel'
import LabResultReferenceInfo from './LabResultReferenceInfo'

// Constants
import { LAB_REPORTS } from '../../../routes/config'

// Queries and Mutations
import { ListLabOrdersDetails } from '../../../graphql/labOrders/queries'

// Styling
import style from './ResultDetails.module.css'

// Utilities
import { ContainerSize } from '../../../utils/container-size/ContainerSize'
import { get } from 'lodash'
import { camelizeKeys } from 'humps'

export interface Test {
  id: string
  name: string
  value: string
  units: string
  status: string
  indicator: string
  notes: Array<string>
  refRange: string
  flagStatement: string
  aka: string
  externalReferences: IExternalReferences
  resultSummaries: ResultSummaries
  testHistory: LabResultTestHistory
}

export default function ResultDetails() {
  const { goBack } = useHistory()
  const {
    state: { labReport },
  } = useLocation()
  const { labReportId, resultId } = useParams<{
    labReportId: string
    resultId: string
  }>()

  const { data, loading: labOrderLoading } = useQuery(ListLabOrdersDetails, {
    variables: {
      labOrderInput: {
        limit: 30,
        sort: {},
        filter: {
          _id: labReportId,
        },
        page: 1,
      },
    },
  })
  const labOrderData: LabReportsResponse =
    data &&
    (camelizeKeys(data.labOrders) as Record<
      string,
      unknown
    > as LabReportsResponse)

  const results = get(labOrderData, 'labOrders[0].results', null)

  let currentTest: Test | undefined = undefined

  if (results) {
    results.map((panel: { tests: any[] }) => {
      panel.tests.map((test) => {
        if (test.id === resultId) {
          currentTest = test
        }
      })
    })
  }

  // const dateReport = get(labOrderData, 'labOrders[0].date', null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // const [size, setSize] = useState([0, 0])
  // function useWindowSize() {
  //   useLayoutEffect(() => {
  //     function updateSize() {
  //       setSize([window.innerWidth, window.innerHeight])
  //     }
  //     window.addEventListener('resize', updateSize)
  //     updateSize()
  //     return () => window.removeEventListener('resize', updateSize)
  //   }, [])
  //   return size
  // }

  if (labOrderLoading) return <Loader active />

  return (
    <div style={{ backgroundColor: '#F8F8F8' }}>
      <DetailsLayout>
        <DetailsLayout.Nav>
          <div
            style={{
              marginTop: 50,
              marginLeft: 45,
            }}
            onClick={() => goBack()}
          >
            <BackButton text={'Back to Lab Report'} goBack={true} />
          </div>
        </DetailsLayout.Nav>

        <DetailsLayout.Panel className={style.leftPanel}>
          <StickyBox offsetTop={50} offsetBottom={20}>
            <ReportDetails labReport={labReport} />

            <div className={style.notesContainer}>
              <LabReportNotes notes={labReport?.notes} />
            </div>
          </StickyBox>
        </DetailsLayout.Panel>

        <DetailsLayout.Panel size={8}>
          <ContainerSize>
            {({ width }) => (
              <Panel>
                <PanelHeader
                  as={NavLink}
                  to={generatePath(LAB_REPORTS.view, { labReportId })}
                >
                  <Icon name="angle left" />
                  <div style={{ fontSize: 18, paddingTop: 5 }}>Back</div>
                </PanelHeader>
                <PanelBody>
                  <LabResultReferenceInfo result={currentTest} />
                  <HistoryChart
                    width={width}
                    // units={currentTest.testHistory.units}
                    results={currentTest.testHistory.results}
                    ranges={currentTest.testHistory.ranges}
                  />
                  <FlagStatement result={currentTest} />

                  <TestReferenceFAQ
                    resultSummaries={currentTest.resultSummaries}
                    aka={currentTest.aka}
                  />

                  <ExternalReferences
                    externalReferences={currentTest.externalReferences.adHoc}
                    result={currentTest}
                  />

                  <ReviewBoardApproved />
                </PanelBody>
              </Panel>
            )}
          </ContainerSize>
        </DetailsLayout.Panel>
      </DetailsLayout>
    </div>
  )
}
