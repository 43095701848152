import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import {
  Modal,
  ErrorModal,
  PasswordStepForm,
  ResetCodeStep,
} from '@labsavvyapp/ui-components'

import { ConfirmCodeUser } from '../../../graphql/user/mutations'
import { RegisterEmailUser } from '../../../graphql/user/mutations'
import style from './SignUpPage.module.css'
import { SIGN } from '../../../routes/config'

interface FormVerificationCode {
  token: string
}

function VerificationCodeScreen() {
  const { push } = useHistory()
  const { formatMessage } = useIntl()
  const { search } = useLocation()
  const [modalSubmitCodeOpen, setModalSubmitCodeOpen] = useState(false)
  const [modalErrorCodeOpen, setModalErrorCodeOpen] = useState(false)
  const [confirmCodeUser] = useMutation(ConfirmCodeUser)
  const [registerEmailUser] = useMutation(RegisterEmailUser)
  const queryParams = new URLSearchParams(search)
  const email = queryParams.get('email')
  /**
   * Form submission.
   */
  const onSubmit = async ({ token }: FormVerificationCode) => {
    try {
      const response = await confirmCodeUser({ variables: { email, token } })
      const proof = response.data.proofUserRegistration
      localStorage.setItem('newUser', JSON.stringify({ email, proof }))
      push(SIGN.upProfileInfo)
    } catch (error) {
      setModalErrorCodeOpen(true)
    }
  }

  const resendCode = () => {
    const partnerCode = JSON.parse(localStorage.getItem('partnerCode'))
    setModalSubmitCodeOpen(true)
    email && registerEmailUser({ variables: { email, partnerCode } })
  }

  useEffect(() => {
    const showModalSubmitCodeTimeout = setTimeout(() => {
      if (modalSubmitCodeOpen === true) setModalSubmitCodeOpen(false)
    }, 3000)
    return () => {
      clearTimeout(showModalSubmitCodeTimeout)
    }
  }, [modalSubmitCodeOpen])

  return (
    <div className={style.root}>
      <Modal open={modalSubmitCodeOpen} showClose={false}>
        {`Code resent to ${email}. Is this the correct email?`}
      </Modal>

      <ErrorModal
        message={'The 6-digit code is invalid.'}
        onCloseClick={() => setModalErrorCodeOpen(false)}
        open={modalErrorCodeOpen}
      />
      <div className={style.formContainer}>
        <PasswordStepForm
          onSubmit={onSubmit}
          currentStep="3"
          totalSteps="5"
          intl={{
            formatMessage,
          }}
          title="Your Code"
          subTitle="Check your email for a 6-digit code to verify your account."
        >
          <ResetCodeStep resendCode={resendCode} />
        </PasswordStepForm>
      </div>
    </div>
  )
}

export default VerificationCodeScreen
