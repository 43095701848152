import { useState } from 'react'
// import { Grid } from 'semantic-ui-react'
// import classnames from 'classnames'
import {
  // Form,
  ErrorModal,
  PasswordStepForm,
  EmailStep,
} from '@labsavvyapp/ui-components'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { useHistory, generatePath } from 'react-router'

import style from './SignUpPage.module.css'
import { SIGN } from '../../../routes/config'
// import ButtonArrowRight from '../../../components/buttons/ButtonArrowRight'
import { RegisterEmailUser } from '../../../graphql/user/mutations'
interface FormPreRegister {
  email: string
}

function PreRegisterEmailScreen() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const [registerEmailUser] = useMutation(RegisterEmailUser)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('error')
  const onSubmit = async ({ email }: FormPreRegister) => {
    const partnerCode = JSON.parse(localStorage.getItem('partnerCode'))
    try {
      await registerEmailUser({ variables: { email, partnerCode } })
      push(generatePath(`${SIGN.upConfirmToken}?email=${email}`))
    } catch (error) {
      setErrorMessage(error?.graphQLErrors[0]?.message)
      setShowErrorModal(true)
    }
  }

  return (
    <div className={style.root}>
      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
      />
      <div className={style.formContainer}>
        <PasswordStepForm
          onSubmit={onSubmit}
          currentStep="2"
          totalSteps="5"
          intl={{
            formatMessage,
          }}
          title="Let's start setting up your account."
          subTitle="What’s your email?"
        >
          <EmailStep />
        </PasswordStepForm>
      </div>
    </div>
  )
}

export default PreRegisterEmailScreen
