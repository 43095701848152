import React from 'react'
import { useForm } from '@labsavvyapp/ui-components'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './ActionInputField.module.css'

function ActionInputField(props) {
  const { updateField, getData, hasErrors } = useForm()

  function handleChange({ target: { value } }) {
    updateField('providerCode', { value })
    props.onChange()
  }

  return (
    <div className={style.actionMainContainer}>
      <input
        {...props}
        autoFocus
        type="text"
        name="providerCode"
        placeholder="ENTER PROVIDER CODE"
        value={hasErrors() ? '' : getData('providerCode') || ''}
        onChange={handleChange}
        className={style.actionInput}
      />
      <button
        className={style.actionButton}
        size="small"
        // disabled={props.loading}
        type="submit"
      >
        {<FontAwesomeIcon icon={faChevronRight} size={'lg'} />}
      </button>
    </div>
  )
}

export default ActionInputField
