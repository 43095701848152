import React, { useState } from 'react'
import { useHistory, useLocation, matchPath } from 'react-router'
import { Image } from 'semantic-ui-react'
import { format } from 'date-fns'
import {
  Button,
  DateField,
  ErrorModal,
  Form,
  InputField,
  ProfileImageUploadField,
  SelectField,
  useSavingModals,
  validate,
  SubmitButton,
} from '@labsavvyapp/ui-components'

import { useMutation, useQuery } from '@apollo/client'
import { UpdateMe } from '../../graphql/user/mutations'
import { GetMe, GetProfile } from '../../graphql/user/queries'
import { isValidDate, isPastDate, isValidDoB } from '../../utils/date'

import { PROFILE } from '../../routes/config'
import style from './ProfileScreen.module.css'
import { useIntl } from 'react-intl'
import { UploadImage } from '../../graphql/files/mutations'
import {
  EnvelopeIcon,
  GenderIcon,
  GiftIcon,
  UserIcon,
} from '../../components/Icons/light'
import profilePhotoIcon from '../../assets/icons/avatar-icon.svg'
import EditIcon from '../../assets/icons/edit.svg'
// import { normalize } from 'path'

interface IProfileProps {
  me: any
}

const ProfileScreen = ({}: IProfileProps) => {
  const { push } = useHistory()
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const isEditing = matchPath(pathname, { path: PROFILE.edit })

  const formatDate = (date: Date) => {
    const localDate = new Date(
      new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000,
    )
    return date ? format(new Date(localDate), 'MM/dd/yyyy') : ''
  }

  // User data
  const { data: profileData } = useQuery(GetProfile)
  const getMe = profileData && profileData.mobileGetMe
  const initialFormData = getMe
    ? {
        ...getMe,
        dob: formatDate(getMe.dob),
      }
    : {}

  const profilePhoto = getMe?.profile_photo?.url

  // Update Profile Photo
  const [uploadImage] = useMutation(UploadImage)
  async function uploadImageFile(file: any) {
    try {
      const payload = await uploadImage({ variables: { file } })
      return (
        payload &&
        payload.data &&
        payload.data.uploadImage &&
        payload.data.uploadImage._id
      )
    } catch (error) {
      setErrorMessage('Error uploading image')
      setShowErrorModal(true)
      throw new Error(error)
    }
  }

  // Update user information
  const [updateMe] = useMutation(UpdateMe, {
    refetchQueries: [
      {
        query: GetMe,
      },
    ],
  })

  // Progress modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving your profile information, please wait...",
    savedMessage: 'Profile information saved.',
    errorMessage: 'Error saving user profile',
    callback: ({ data }: any) =>
      updateMe({
        variables: {
          data,
        },
      }),
  })

  const handleUpdate = (formData: any) => {
    const { name, emails, profile_photo_id, dob, gender } = formData

    const dobParsed = new Date(dob)

    showModals({
      data: {
        name: {
          first: name.first,
          last: name.last,
        },
        emails: [
          {
            address: emails[0].address,
          },
        ],
        profile_photo_id,
        dob: dobParsed,
        gender,
      },
    })

    push(PROFILE.base)
  }

  const handleEdit = () => push(`${PROFILE.edit}`)

  return (
    <main className={style.main}>
      <Form
        intl={{
          formatMessage,
        }}
        initialFormData={initialFormData}
        onSubmit={handleUpdate}
      >
        <div className={style.header}>
          <h1 className={style.pageTitle}>
            {`${isEditing ? 'Edit' : ''} Profile`}
          </h1>
          {isEditing ? (
            <div className={style.editSubmit}>
              <Button variant="basic" onClick={() => push(PROFILE.base)}>
                Cancel
              </Button>
              <SubmitButton>Save</SubmitButton>
            </div>
          ) : (
            <div onClick={handleEdit} className={style.editButton}>
              <div>Edit profile</div>
              <Image src={EditIcon} />
            </div>
          )}
        </div>
        <div className={style.content}>
          <div className={style.imageField}>
            <h1 className={style.avatarTitle}>{getMe?.name?.display}</h1>

            {!isEditing ? (
              <>
                {profilePhoto ? (
                  <Image
                    src={profilePhoto}
                    size="small"
                    circular
                    style={{ width: 110, height: 110 }}
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: '#EFEEEE',
                      borderRadius: 80,
                      padding: 35,
                      width: 110,
                      height: 110,
                    }}
                  >
                    <img
                      style={{ color: 'red' }}
                      src={profilePhotoIcon}
                      alt="profile_photo_id"
                    />
                  </div>
                )}
              </>
            ) : (
              <ProfileImageUploadField
                disabled={true}
                image={profilePhoto}
                name="profile_photo_id"
                uploadFn={uploadImageFile}
                plusIconBottom={true}
              />
            )}
          </div>
          <div className={style.columnFields}>
            {isEditing && (
              <div className={style.warning}>
                <strong>Warning:</strong> LabSavvy uses your profile information
                to match and connect you with your test results. If your
                information does not match this may cause issues with access to
                your results.
              </div>
            )}
            <div className={style.formField}>
              <InputField
                className={style.inputField}
                inputType="underline"
                name="name.first"
                disabled={!isEditing}
                placeholder="First Name"
                validate={validate.notEmpty()}
              />
              <UserIcon size="lg" className={style.icon} />
            </div>
            <div className={style.formField}>
              <InputField
                className={style.inputField}
                disabled={!isEditing}
                inputType="underline"
                name="name.last"
                placeholder="Last Name"
                validate={validate.notEmpty()}
              />
              <UserIcon size="lg" className={style.icon} />
            </div>
            <div className={style.formField}>
              <InputField
                className={style.inputField}
                disabled={!isEditing}
                inputType="underline"
                name="emails.0.address"
                placeholder="Email"
                validate={validate.combine(
                  validate.notEmpty(),
                  validate.isEmail(),
                )}
              />
              <EnvelopeIcon size="lg" className={style.icon} />
            </div>
            <div className={style.formField}>
              <DateField
                className={style.dateInputField}
                disabled={!isEditing}
                inputType="underline"
                name="dob"
                placeholder="mm/dd/yyyy"
                validate={validate.combine(
                  validate.notEmpty(),
                  isValidDate,
                  isValidDoB,
                  isPastDate,
                )}
              />
              <GiftIcon size="lg" className={style.icon} />
            </div>
            {!isEditing ? (
              <div className={style.formField}>
                <InputField
                  className={style.inputField}
                  disabled={!isEditing}
                  inputType="underline"
                  name="gender"
                  placeholder="Gender"
                  validate={validate.notEmpty()}
                />
                <GenderIcon size="lg" className={style.icon} />
              </div>
            ) : (
              <SelectField
                className={style.selectField}
                disabled={!isEditing}
                inputType="underline"
                name="gender"
                options={[
                  { text: 'Male', value: 'male' },
                  { text: 'Female', value: 'female' },
                ]}
                validate={validate.selected()}
              />
            )}
            {!isEditing && (
              <div
                className={style.changePassword}
                onClick={() => push(PROFILE.email)}
              >
                CHANGE PASSWORD
              </div>
            )}
          </div>
        </div>
      </Form>
      {modals}
      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
      />
    </main>
  )
}

export default ProfileScreen
