import React from 'react'
import classnames from 'classnames'
import { Icon } from 'semantic-ui-react'
import { NavLink, useParams, generatePath } from 'react-router-dom'

import { ForwardIcon } from '../../../components/Icons/light'
import { LAB_REPORTS } from '../../../routes/config'
import style from './LabReportTestResult.module.css'

interface LabReportTestResultProps {
  test: {
    id: string
    status: string
    name: string
    value: string
    units: string
  }
}

export default function LabReportTestResult({
  test,
  labReport,
}: LabReportTestResultProps) {
  const { labReportId } = useParams<{ labReportId: string }>()

  return (
    <NavLink
      data-test="link-result"
      to={{
        pathname: generatePath(LAB_REPORTS.resultDetails, {
          labReportId,
          resultId: test.id,
        }),
        state: { labReport },
      }}
      className={classnames(style.container, {
        [style.statusAbnormal]: test.status === 'abnormal',
        [style.statusBorderline]: test.status === 'borderline',
        [style.editing]: true,
      })}
    >
      <Icon
        className={style.statusIcon}
        name="circle"
        size="small"
        color={
          (test.value === null && 'grey') ||
          (test.status === 'normal' && 'green') ||
          (test.status === 'borderline' && 'yellow') ||
          (test.status === 'abnormal' && 'red') ||
          'grey'
        }
      />
      <div className={style.name}>{test.name}</div>
      <div className={style.value}>
        {test.value} {test.units}
      </div>
      <ForwardIcon className={style.angle} />
    </NavLink>
  )
}
