import { Card } from '@labsavvyapp/ui-components'
import style from './LabReportNote.module.css'

interface NotesProps {
  notes: Array<string>
}

export default function LabReportNotes({ notes }: NotesProps) {
  return (
    <div className={style.container}>
      <div className={style.title}>NOTES</div>
      {notes &&
        notes.map((note, i) => (
          <div
            key={i}
            data-test="lab-report-note"
            className={style.text}
            dangerouslySetInnerHTML={{ __html: note || '' }}
          />
        ))}

      {notes && !notes.length && (
        <Card data-test="card-no-notes" emptyCard shadowed>
          No order notes found.
        </Card>
      )}
    </div>
  )
}
