import React from 'react'

import {
  VerticalTabMenu,
  VerticalTabMenuItemCard,
  VerticalTabMenuItemLink,
} from '@labsavvyapp/ui-components'
import { SETTINGS } from '../../../routes/config'
import { Route, Switch, useHistory, useLocation } from 'react-router'
import TransactionsScreen from './TransactionsScreen'
import PaymentMethodPage from './PaymentMethodPage'

import style from './AccountPage.module.css'

interface IAccountLink {
  id: number
  text: string
  url: string
}

interface ILink {
  text: string
  url: string
}

const accountLinks: IAccountLink[] = [
  {
    id: 1,
    text: 'Payment Method',
    url: `${SETTINGS.paymentMethod}`,
  },
  {
    id: 2,
    text: 'Transaction History',
    url: `${SETTINGS.transactions}`,
  },
]

const links: ILink[] = [
  {
    text: 'Delete your Account',
    url:
      process.env.REACT_APP_SUPPORT_REQUEST_ACCOUNT_DEL_URL ||
      'http://labsavvy.com/support-2',
  },
]

const AccountPage = () => {
  const { push } = useHistory()
  const { pathname } = useLocation()

  const handleSelect = (item: IAccountLink) => push(item.url)
  return (
    <>
      <div className={style.accountContainer}>
        <VerticalTabMenu>
          {accountLinks.map((link: IAccountLink) => (
            <VerticalTabMenuItemCard
              item={link}
              key={link.id}
              title={link.text}
              size={50}
              selected={pathname === link.url}
              onClick={handleSelect}
            />
          ))}
          {links.map((item) => (
            <VerticalTabMenuItemLink
              key={item.text}
              text={item.text}
              url={item.url}
            />
          ))}
        </VerticalTabMenu>
      </div>
      <Switch>
        <Route
          exact
          path={SETTINGS.paymentMethod}
          component={PaymentMethodPage}
        />
        <Route
          exact
          path={SETTINGS.transactions}
          component={TransactionsScreen}
        />
      </Switch>
    </>
  )
}

export default AccountPage
