import React from 'react'
// import { useMutation } from '@apollo/client'
import classnames from 'classnames'
import { Button } from '@labsavvyapp/ui-components'
import { NameInitials } from '@labsavvyapp/ui-components'

import style from './style.module.css'

export default function ContactProfile({ contact, onAdd, onRevoke }) {
  if (!contact) return <section className={style.container}></section>
  const { name, jobTitle, profilePhoto, memberBio, revoked, connected } =
    contact

  return (
    <section className={style.container}>
      <div className={style.containerImage}>
        {profilePhoto ? (
          <>
            <img
              className={style.imageMain}
              src={profilePhoto?.url}
              alt="Client"
            />
            <img className={style.image} src={profilePhoto.url} alt="Client" />
          </>
        ) : (
          <div className={style.nameInitials}>
            <NameInitials
              firstName={name.first}
              lastName={name.last}
              size={44}
            />
          </div>
        )}
      </div>
      <div className={style.containerInfo}>
        <h1 className={style.name}>{name.display}</h1>
        <h2 className={style.subtitle}>{jobTitle}</h2>
        <p className={classnames(style.subtitle, style.description)}>
          {memberBio || 'No description available.'}
        </p>
      </div>

      <div className={style.containerFooter}>
        {revoked ? (
          <Button
            onClick={(e) => {
              e.preventDefault()
              onAdd(contact)
            }}
          >
            RESTORE ACCESS
          </Button>
        ) : connected ? (
          <Button
            className={style.revoked}
            onClick={(e) => {
              e.preventDefault()
              onRevoke(contact)
            }}
          >
            REVOKE ACCESS
          </Button>
        ) : (
          <Button
            onClick={(e) => {
              e.preventDefault()
              onAdd(contact)
            }}
          >
            ADD TO YOUR TEAM
          </Button>
        )}
      </div>
    </section>
  )
}
