/* eslint-disable no-unused-vars */

import React from 'react'
import get from 'lodash/get'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'
import { camelizeKeys } from 'humps'
import { Loader } from 'semantic-ui-react'

import { LAB_REPORTS } from '../../../routes/config'
import { ListLabOrdersDetails } from '../../../graphql/labOrders/queries'
import { DetailsLayout } from '../../../layouts/DetailsLayout/DetailsLayout'
import { Test } from '../resultdetails/ResultDetails'
import style from './LabReportViewPage.module.css'
import ReportDetails from '../../../components/orderdetails/ReportDetails'
import LabReportNotes from './LabReportNotes'
import LabReportTestResult from './LabReportTestResult'
import LabReportResultCategory from './LabReportResultCategory'
import BackButton from '../../../components/BackButton/BackButton'
import StickyBox from 'react-sticky-box'

export interface LabReportData {
  id: string
  shortId: string
  name: string
  date: Date
  orderedAt: Date
  collectedAt: Date
  reportedAt: Date
  status: string
  notes: Array<string>
  partner: {
    name: string
  }
  provider: {
    name: string
  }
  summary: {
    normal: number
    abnormal: number
  }
  results: Array<Result>
}

export interface Result {
  name: string
  subtitle: string
  summary: string
  details: string
  icon: {
    url: string
  }
  tests: Array<Test>
}

export interface LabReportsResponse {
  labOrders: Array<LabReportData>
  page: number
  pages: number
  total: number
}

export default function LabReportViewPage() {
  // const { push, goBack } = useHistory()
  const { labReportId } = useParams<{ labReportId: string }>()

  const { data, loading: labOrderLoading } = useQuery(ListLabOrdersDetails, {
    variables: {
      labOrderInput: {
        limit: 30,
        sort: {},
        filter: {
          _id: labReportId,
        },
        page: 1,
      },
    },
  })

  const labOrderData: LabReportsResponse =
    data &&
    (camelizeKeys(data.labOrders) as Record<
      string,
      unknown
    > as LabReportsResponse)
  const labOrder: LabReportData = labOrderData && labOrderData.labOrders[0]
  const urlReport = get(labOrder, 'resultDocuments[0].resultsPdfReport.url', '')

  //revamp

  // handle scroll position after content load
  // const handleScrollPosition = () => {
  //   const scrollPosition = sessionStorage.getItem('scrollPosition')
  //   if (scrollPosition) {
  //     window.scrollTo(0, parseInt(scrollPosition))
  //     sessionStorage.removeItem('scrollPosition')
  //   }
  // }

  // store position in sessionStorage
  const handleClick = () => {
    sessionStorage.setItem('scrollPosition', window.pageYOffset)
  }

  // useEffect(() => {
  //   handleScrollPosition()
  // }, [])

  return (
    <div className={style.container}>
      <DetailsLayout>
        <DetailsLayout.Nav>
          <div style={{ marginTop: 50, marginLeft: 45 }}>
            <BackButton text={'Back to Lab Reports'} to={LAB_REPORTS.base} />
          </div>
        </DetailsLayout.Nav>
        <DetailsLayout.Panel className={style.leftPanel}>
          <StickyBox offsetTop={50} offsetBottom={20}>
            {labOrderLoading && <Loader active inline="centered" />}
            <ReportDetails labReport={labOrder} url={urlReport} />

            <div className={style.notesContainer}>
              <LabReportNotes notes={labOrder?.notes} />
            </div>
          </StickyBox>
        </DetailsLayout.Panel>

        <DetailsLayout.Panel size={8}>
          <div className={style.rightPanel}>
            {labOrder &&
              labOrder.results.map((result, key) => (
                <LabReportResultCategory
                  key={key}
                  name={result.name}
                  subtitle={result.subtitle}
                  summary={result.summary}
                  image={result.icon ? result.icon.url : ''}
                  style={{ paddingBottom: '20px' }}
                  details={result.details}
                >
                  {result.tests
                    ? result.tests.map((test, index) => (
                        <div onClick={() => handleClick()} key={index}>
                          <LabReportTestResult
                            key={`LabReportTestResult-${index}`}
                            test={test}
                            labReport={labOrder}
                          />
                        </div>
                      ))
                    : null}
                </LabReportResultCategory>
              ))}
          </div>
        </DetailsLayout.Panel>
      </DetailsLayout>
    </div>
  )
}
