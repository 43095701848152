import { gql } from '@apollo/client'

// creates a payment intent and called upon clicking checkout button
export const StartStoreCart = gql`
  mutation startStoreCart($amount: Float!, $items: [ProductItem!]!) {
    startStoreCart(amount: $amount, items: $items) {
      client_secret
      stripe_customer_id
      payment_intent_id
      default_payment_method {
        id
        last4
        default
        brand
      }
    }
  }
`

export const AddCreditCard = gql`
  mutation mobileAddPaymentMethod($sourceId: String!) {
    mobileAddPaymentMethod(sourceId: $sourceId) {
      id
      brand
      last4
      default
    }
  }
`

export const RemoveCreditCard = gql`
  mutation mobileDeletePaymentMethod($sourceId: String!) {
    mobileDeletePaymentMethod(sourceId: $sourceId)
  }
`

export const FinalizeCart = gql`
  mutation finalizeStoreCart($cart: Cart!) {
    finalizeStoreCart(cart: $cart) {
      client_secret
      stripe_customer_id
      payment_intent_id
    }
  }
`
