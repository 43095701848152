import React, { useCallback } from 'react'

import useLocalState from '../hooks/useLocalState'
import { trigger } from '../events/shopEvent'

interface ShopContextProps {
  children: React.ReactElement
}

interface ProductMarketing {
  cover_image: { url: string }
  summary: string
}

export interface Product {
  _id: string
  marketing: ProductMarketing
  name: string
  partner_name: string
  price: number
  total_price: number
  details: string
}

export interface Card {
  brand: string
  id: string
  last4: string
  default: boolean
}

export interface ShopState {
  [key: string]: { amount: number; data: Product }
}

export const ShopContext = React.createContext({
  items: {} as ShopState,
  addItem: () => {},
  removeItem: () => {},
  removeAll: () => {},
  size: (): number => 0,
  IsInTheBag: (): boolean => false,
  getTotalPrice: (): number => 0,
})

export function ShopProvider({ children }: ShopContextProps) {
  const [items, setItems] = useLocalState('shop_bag', {})

  const addItem = useCallback(
    (newItem) => {
      if (items[`${newItem._id}`]) {
        items[`${newItem._id}`].amount += 1
      } else {
        items[`${newItem._id}`] = { amount: 1, data: newItem }
      }
      setItems({ ...items })
      trigger('newitemadded')
    },
    [items],
  )

  const removeItem = useCallback(
    (idToRemove) => {
      if (items[`${idToRemove}`]) {
        const { amount } = items[`${idToRemove}`]

        if (amount <= 1) {
          delete items[`${idToRemove}`]
        } else {
          items[`${idToRemove}`].amount -= 1
        }
      }
      setItems({ ...items })
    },
    [items],
  )

  const removeAll = useCallback(() => setItems({}), [])

  const size = useCallback(() => {
    const ids = Object.keys(items)
    let count = 0
    ids.forEach((id) => {
      count += items[id].amount
    })
    return count
  }, [items])

  const IsInTheBag = React.useCallback(
    (idToFind: string): boolean => {
      const productIds = Object.keys(items).filter((id) => id === idToFind)
      return productIds.length > 0
    },
    [items],
  )

  const getTotalPrice = useCallback(() => {
    const ids = Object.keys(items)
    let amount = 0
    ids.forEach((id) => {
      amount += items[id].amount * items[id].data.total_price
    })
    return amount
  }, [items])

  return (
    <ShopContext.Provider
      value={{
        items,
        addItem,
        size,
        IsInTheBag,
        removeItem,
        getTotalPrice,
        removeAll,
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}
