import React from 'react'

import style from './Panel.module.css'

export default function Panel({ as = 'div', children, className }) {
  const Element = as

  return <Element className={className || style.Panel}>{children}</Element>
}

export const PanelHeader = ({ as = 'div', className, ...props }) => {
  const Element = as

  return <Element className={className || style.PanelHeader} {...props} />
}

export const PanelBody = ({ as = 'div', className, ...props }) => {
  const Element = as

  return <Element className={className || style.PanelBody} {...props} />
}
