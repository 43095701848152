import React, { Fragment } from 'react'
import { Icon } from 'semantic-ui-react'
import { Button } from '@labsavvyapp/ui-components'
import { useHistory } from 'react-router'
import { ShopContext, Product } from '../../../components/context/ShopContext'
import styles from './BagPage.module.css'
import BagItemList from './BagItemList'
import { STORE } from '../../../routes/config'
import BackButton from '../../../components/BackButton/BackButton'
import formatterPrice from '../../../utils/formatter-price'

export default function BagPage() {
  const { push } = useHistory()
  const shopContext = React.useContext(ShopContext)
  const productIds = Object.keys(shopContext.items)
  const totalPrice = shopContext.getTotalPrice()

  const goToCheckout = () => {
    push(STORE.checkout)
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <BackButton text="Back to STORE" to={STORE.base} />
        <p className={styles.mainHeaderText}>Your Cart</p>
      </div>

      <div className={styles.cartItemHeader}>
        <div className={styles.removeButtonColumn}>
          <Icon disabled name="trash" />
        </div>
        <div className={styles.nameColumn}>
          <div className={styles.headerText}>ITEM</div>
        </div>
        <div className={styles.priceColumn}>
          <div className={styles.headerText}>PRICE</div>
        </div>
      </div>

      <div className={styles.productRow}>
        {productIds.map((id) => {
          const product: Product = shopContext.items[id].data
          return (
            <Fragment key={`${id}`}>
              <BagItemList
                name={product.name}
                total_price={product.total_price}
                partner_name={product.partner_name}
                id={product._id}
              />
              <div className={styles.itemDivider} />
            </Fragment>
          )
        })}
      </div>

      {!productIds.length && (
        <>
          <div style={{ fontWeight: 900, fontSize: 24, marginTop: 100 }}>
            Your cart is empty
          </div>
          <a
            style={{ marginTop: 10, cursor: 'pointer', color: '#564C96' }}
            onClick={() => {
              push(STORE.base)
            }}
          >
            Continue Shopping
          </a>
        </>
      )}

      <div className={styles.buttonsContainer}>
        <div className={styles.pricingText}>
          Total ({productIds.length} items):{' '}
          <span className={styles.priceTitle}>
            {formatterPrice(totalPrice)}
          </span>
        </div>
        <Button
          className={styles.checkoutButton}
          onClick={goToCheckout}
          disabled={!productIds.length}
        >
          CHECKOUT
        </Button>
      </div>
    </div>
  )
}
