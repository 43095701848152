import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'

import {
  Button,
  ProfilePhoto,
  PasswordStepForm,
  ChangePasswordStep,
  useSavingModals,
} from '@labsavvyapp/ui-components'

import { RecoverEmail } from '../../../graphql/user/mutations'
import style from './ChangePassword.module.css'
import { PROFILE, SIGN } from '../../../routes/config'

interface FormPreRegister {
  confirmPassword: string
}

interface IProps {
  me: any
}

function Step3({ me }: IProps) {
  const { push } = useHistory()
  const { search, pathname } = useLocation()
  const [changePassword] = useMutation(RecoverEmail)
  const intl = useIntl()

  const queryParams = new URLSearchParams(search)
  const email = queryParams.get('email')
  const proof = queryParams.get('proof')

  let nextPath = ''

  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're processing",
    savedMessage: 'Your password has been successfully updated.',
    errorMessage: 'Error changing password',
    callback: (data: FormPreRegister) => {
      nextPath = `${pathname.includes('/profile') ? PROFILE.base : SIGN.base}`
      return changePassword({
        variables: {
          proof: proof,
          email: email,
          newPassword: data.confirmPassword,
        },
      })
    },
    onSuccess: () => push(nextPath),
  })

  return (
    <>
      <main className={style.main}>
        <div className={style.header}>
          <h1 className={style.pageTitle}>Change Password</h1>
          <div className={style.cancelButton}>
            <Button variant="basic" onClick={() => push(PROFILE.base)}>
              Cancel
            </Button>
          </div>
        </div>
        <div className={style.content}>
          <div className={style.avatar}>
            <h1 className={style.avatarTitle}>{me?.name?.display}</h1>
            <ProfilePhoto size={110} url={me?.profile_photo?.url} />
          </div>
          <div className={style.columnFields}>
            <div className={style.passwordStepContainer}>
              <PasswordStepForm
                type="change"
                onSubmit={showModals}
                currentStep="3"
                totalSteps="3"
                intl={intl}
                title="Choose a Password"
                submitButtonText="Save Password"
              >
                <div
                  style={{
                    textAlign: 'left',
                    marginBottom: 30,
                    fontWeight: 200,
                  }}
                >
                  <p>Please enter the new password</p>
                  <ul>
                    <li>8 to 12 characters in length</li>
                    <li>at least 1 Capital letter</li>
                    <li>at least 1 Lowercase letter</li>
                    <li>at least 1 Number, and</li>
                    <li>at least 1 Special character</li>
                  </ul>
                </div>
                <ChangePasswordStep />
              </PasswordStepForm>
            </div>
          </div>
        </div>
      </main>
      {modals}
    </>
  )
}

export default Step3
