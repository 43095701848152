import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Image } from 'semantic-ui-react'

import {
  Button,
  ErrorModal,
  Form,
  InputField,
  SubmitButton,
  validate,
  useSavingModals,
} from '@labsavvyapp/ui-components'

import { useMutation, useQuery } from '@apollo/client'
import { GetPartnerInfoByPartnerCode } from '../../graphql/connected-providers/queries'
import { ConnectWithPartnerCode } from '../../graphql/connected-providers/mutations'
import { SETTINGS } from '../../routes/config'

import style from './ConnectToProvidersScreen.module.css'
import { useIntl } from 'react-intl'
import { ForwardIcon } from '../../components/Icons/light'

// interface IPartner {
//   _id: string
//   name: string
//   connected: boolean
//   logo: {
//     _id: string
//     url: string
//     versions: {
//       thumb: string
//     }
//   }
// }

interface IParams {
  id: string
}

const ConnectToProvidersScreen = () => {
  const { id: partnerCode } = useParams<IParams>()
  const { push } = useHistory()
  const { formatMessage } = useIntl()
  const [hipaaAuth, setHipaaAuth] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const { data } = useQuery(GetPartnerInfoByPartnerCode, {
    variables: {
      code: partnerCode,
    },
    skip: !partnerCode,
  })

  const [connectToPartner] = useMutation(ConnectWithPartnerCode)

  // Progress modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're connecting to partner",
    savedMessage: 'Connected!',
    errorMessage: 'Error connecting to partner',
    callback: ({ partnerCode }: any) => {
      connectToPartner({
        variables: {
          partnerCode,
        },
      })
    },
    onSuccess: () => push(SETTINGS.connectedProviders),
  })

  const selectedPartner = data?.mobileGetPartnerInfoByPartnerCode

  const handleSubmit = async (data: any) => {
    push(`${SETTINGS.connectToProvider}/${data.partnerCode}`)
  }

  const handleConnect = () => {
    if (!hipaaAuth) {
      return setShowErrorModal(true)
    }

    return showModals({ partnerCode })
  }

  return (
    <>
      <div className={style.providersList}>
        <div className={style.addCode}>
          <Form
            intl={{
              formatMessage,
            }}
            initialFormData={{ partnerCode }}
            onSubmit={handleSubmit}
          >
            <div className={style.inputPartnerCode}>
              <InputField
                className={style.inputField}
                inputClassName={style.inputField}
                name="partnerCode"
                placeholder="ENTER PARTNER CODE"
                validate={validate.notEmpty}
              />
              <div className={style.submitButton}>
                <SubmitButton>
                  <ForwardIcon size={'3x'} />
                </SubmitButton>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {selectedPartner && (
        <div className={style.providerDetails}>
          <Image
            src={selectedPartner?.project_logo?.url}
            className={style.photo}
          />
          <h3 className={style.providerName}>{selectedPartner.name}</h3>
          <h4>
            Connect and import your current lab results from this LabSavvy
            partner and to allow for automatic retrieval of future lab results
            and health records.
          </h4>
          <div>
            <input
              type="radio"
              checked={hipaaAuth}
              className={style.checkbox}
              onClick={() => setHipaaAuth(!hipaaAuth)}
            />
            I have read and accept our
            <a
              href={process.env.REACT_APP_HIPAA_AUTH_URL}
              target="_blank"
              rel="noreferrer"
            >
              {` HIPAA Authorization `}
            </a>
            and
            <a
              href={process.env.REACT_APP_TERMS_OF_SERVICE}
              target="_blank"
              rel="noreferrer"
            >
              {` Terms of Service `}
            </a>
          </div>
          <div>
            <Button
              className={style.connectButton}
              variant="primary"
              onClick={handleConnect}
            >
              Connect
            </Button>
          </div>
          <ErrorModal
            message={'Accept HIPAA Terms!'}
            onCloseClick={() => setShowErrorModal(false)}
            open={showErrorModal}
          />
          {modals}
        </div>
      )}
    </>
  )
}

export default ConnectToProvidersScreen
