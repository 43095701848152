import React from 'react'
import { SubmitButton } from '@labsavvyapp/ui-components'
import { ReactComponent as ArrowIcon } from './arrow-right.svg'
import style from './ButtonArrowRight.module.css'

interface ButtonArrowRightProps {
  disabled?: boolean
  text: string
  onClick?: any
}

export default function ButtonArrowRight(props: ButtonArrowRightProps) {
  return (
    <SubmitButton
      className={style.nextButton}
      data-test={'next'}
      variant="basic"
      {...props}
    >
      <div className={style.buttonContentRoot}>
        {props.text}
        <ArrowIcon className={style.iconStyles} />
      </div>
    </SubmitButton>
  )
}
