import React, { useRef, useEffect } from 'react'
import { Loader } from '@labsavvyapp/ui-components'
import InfiniteScroll from 'react-infinite-scroller'

import style from './index.module.css'

function MessageList({
  children = [],
  onNextPage,
  hasMore,
  initialLoad,
  scrollToBottom,
  onScrollToBottom,
}) {
  const containerRef = useRef(null)
  const scrollToBottomRef = useRef(true)

  useEffect(() => {
    // TODO investigate the useWindow property it seems that it adds listeners on the window object
    // Is it possible to trigger events that makes it scroll to the bottom
    // https://github.com/danbovey/react-infinite-scroller#readme
    if (containerRef.current && (scrollToBottomRef.current || scrollToBottom)) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
    onScrollToBottom && onScrollToBottom()
  }, [
    containerRef.current,
    children,
    scrollToBottomRef.current,
    scrollToBottom,
  ])

  const onScrollHandler = () => {
    scrollToBottomRef.current = false
  }

  return (
    <>
      <section
        onScroll={onScrollHandler}
        className={style.container}
        ref={containerRef}
      >
        <InfiniteScroll
          initialLoad={initialLoad}
          loadMore={onNextPage}
          hasMore={hasMore}
          isReverse={true}
          loader={<Loader />}
          threshold={150}
          useWindow={false}
        >
          {children}
        </InfiniteScroll>
      </section>
    </>
  )
}

export default MessageList
export { Sender, Receiver } from './Message/index.js'
