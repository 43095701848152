import React from 'react'

import style from './Header.module.css'

interface Props {
  children: any
}

export default function Header({ children }: Props) {
  return <header className={style.container}>{children}</header>
}

Header.layoutElementName = 'Header'
