import { gql } from '@apollo/client'

const UserNameFragment = gql`
  fragment UserName on User {
    name {
      first
      last
      display
    }
  }
`

export const GetSession = gql`
  query GetSession {
    session @client {
      token
      isLoggedIn
    }
  }
`

const UserEmailsFragment = gql`
  fragment UserEmails on User {
    emails {
      address
    }
  }
`

const UserPhotoFragment = gql`
  fragment UserPhoto on User {
    profile_photo {
      _id
      url
    }
  }
`

export const GetMe = gql`
  query MobileGetMe {
    mobileGetMe {
      _id
      created_at
      name {
        first
        last
        display
      }
      dob
      gender
      profile_photo {
        _id
        name
        url
        versions {
          thumb
          small
        }
      }
      emails {
        address
      }
      capabilities {
        canReleaseResults
        isAccountOwner
        isSuperAdmin
        isReadOnlyPartnerManager
      }
    }
  }
`
export const GetProfile = gql`
  query GetProfile {
    mobileGetMe {
      _id
      dob
      gender
      ...UserName
      ...UserEmails
      ...UserPhoto
    }
  }

  ${UserNameFragment}
  ${UserEmailsFragment}
  ${UserPhotoFragment}
`
