import React from 'react'
import { useIntl } from 'react-intl'
import {
  validate,
  DateField,
  SelectField,
  InputField,
  PasswordStepForm,
} from '@labsavvyapp/ui-components'
import style from './SignUpPage.module.css'
import { useHistory } from 'react-router'
import { SIGN } from '../../../routes/config'
import dateIsoUtcString from '../../../utils/date-iso-utc-string'
import { isValidDate, isPastDate, isValidDoB } from '../../../utils/date'
import { GiftIcon, UserIcon, GenderIcon } from '../../../components/Icons/light'

interface FormProfileCreationScreen {
  proof: string
  email: string
  password: string
  first_name: string
  last_name: string
  dob: string
  gender: string
}

function ProfileCreationScreen() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()

  const onSubmit = (userData: FormProfileCreationScreen) => {
    userData.dob = dateIsoUtcString(userData.dob)
    const userJson = JSON.parse(localStorage.getItem('newUser') || '{}')
    localStorage.setItem(
      'newUser',
      JSON.stringify({ ...userData, ...userJson }),
    )
    push(SIGN.upSetupPasword)
  }

  return (
    <div className={style.root}>
      <div className={style.formContainer}>
        <PasswordStepForm
          onSubmit={onSubmit}
          currentStep="4"
          totalSteps="5"
          intl={{
            formatMessage,
          }}
          title="Who are you?"
          subTitle="Your main profile information"
        >
          <div className={style.formField}>
            <InputField
              className={style.input}
              inputType="underline"
              name="firstName"
              placeholder="First Name"
              validate={validate.notEmpty()}
            />
            <UserIcon size="lg" className={style.icon} />
          </div>
          <div className={style.formField}>
            <InputField
              className={style.input}
              inputType="underline"
              name="lastName"
              placeholder="Last Name"
              validate={validate.notEmpty()}
            />
            <UserIcon size="lg" className={style.icon} />
          </div>
          <div className={style.formField}>
            <DateField
              className={style.input + ` ${style.dateField}`}
              inputType="underline"
              name="dob"
              placeholder="mm/dd/yyyy"
              validate={validate.combine(
                validate.notEmpty(),
                isValidDate,
                isPastDate,
                isValidDoB,
              )}
            />
            <GiftIcon size="lg" className={style.icon} />
          </div>

          <div className={style.formField}>
            <SelectField
              name="gender"
              className={style.input}
              placeholder="Sex"
              options={[
                { text: 'Male', value: 'male' },
                { text: 'Female', value: 'female' },
              ]}
              validate={validate.selected()}
            />
            <GenderIcon size="lg" className={style.icon} />
          </div>
        </PasswordStepForm>
      </div>
    </div>
  )
}

export default ProfileCreationScreen
