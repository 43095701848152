import React from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import { useSavingModals } from '@labsavvyapp/ui-components'
import { useHistory } from 'react-router'
import { useMutation } from '@apollo/client'
import { AddCreditCard } from '../../../graphql/ecommerce/mutation'
import styles from './UpdatePayment.module.css'
import CardForm from './CardForm'
import { Message } from 'semantic-ui-react'

const stripePromise: PromiseLike<Stripe | null> =
  (process.env.REACT_APP_USE_ECOMMERCE === 'true' &&
    loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '')) ||
  Promise.resolve(null)

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
}

export default function UpdatePaymentPage() {
  const history = useHistory()
  const [addCreditCard] = useMutation(AddCreditCard)
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving your payment method, please wait...",
    savedMessage: 'Your payment method has been saved',
    callback: async (sourceId: string) => {
      await addCreditCard({
        variables: { sourceId },
      })
    },
    onSuccess: () => {
      history.goBack()
    },
    onError: (error: any) => error,
  })

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <p className={styles.mainHeaderText}>Payment Method</p>
      </div>
      <div className={styles.centerContainer}>
        <div className={styles.cardContent}>
          <div className={styles.columnCard}>
            <div className={styles.cardHeaderText}>Update Card</div>
            <Message positive>
              <Message.Header>Your card details are protected.</Message.Header>
              <p>
                We partner with Stripe to ensure that your card details are kept
                safe and secure. LabSavvy will not have access to your card
                info.
              </p>
            </Message>

            <div className={styles.elementsContainer}>
              <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <CardForm onSave={showModals} />
              </Elements>
            </div>
          </div>
        </div>
        {modals}
      </div>
    </div>
  )
}
