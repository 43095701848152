import { useQuery } from '@apollo/client'
import {
  InfiniteScrollList,
  List,
  ListLoader,
  ListEmpty,
} from '@labsavvyapp/ui-components'
import {
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/ui-components/lib/utils'
import { camelizeKeys } from 'humps'
import { useEffect } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'

import { ListLabOrders } from '../../../graphql/labOrders/queries'
import LabReportRow, { LabReportData } from './LabReportRow'
import style from './LabReportsListPage.module.css'
// import _ from 'lodash'

interface LabOrdersResponse {
  labOrders: Array<LabReportData>
  page: number
  pages: number
  total: number
}

export default function LabReportsListPage() {
  const { data, loading, fetchMore, refetch } = useQuery(ListLabOrders, {
    variables: {
      labOrderInput: {
        limit: 30,
        sort: { created_at: 'desc' },
        filter: {},
        page: 1,
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [])

  const labOrdersData: LabOrdersResponse =
    data &&
    (camelizeKeys(data.labOrders) as unknown as Record<
      string,
      LabOrdersResponse
    >)

  let items: Array<LabReportData> = []
  const pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (labOrdersData) {
    items = labOrdersData.labOrders
    pagination.page = labOrdersData.page
    pagination.pages = labOrdersData.pages
    pagination.total = labOrdersData.total
  }

  return (
    <div className={style.root}>
      <PageHeader title="Lab Reports" />
      <List>
        <InfiniteScrollList
          scrollableTarget="lab-report-list"
          containerClassName={style.infiniteScrollContainer}
          dataLength={items.length}
          hasMore={hasMore(pagination)}
          next={() =>
            fetchNext('labOrders', 'lab_orders', {
              page: pagination.page,
              fetchMore,
            })
          }
        >
          {!isEmpty(items) &&
            items.map((item, index) => (
              <>
                <LabReportRow key={item.id + index} data={item} />
              </>
            ))}

          <ListLoader
            fetched={items.length}
            total={pagination.total}
            loading={loading}
          />

          {!loading && isEmpty(items) && (
            <ListEmpty message="No lab orders found." />
          )}
        </InfiniteScrollList>
      </List>
    </div>
  )
}
