type ShopEvent = 'newitemadded'

function on(
  eventType: ShopEvent,
  listener: EventListenerOrEventListenerObject,
) {
  document.addEventListener(eventType, listener)
}

function off(
  eventType: ShopEvent,
  listener: EventListenerOrEventListenerObject,
) {
  document.removeEventListener(eventType, listener)
}

function trigger(eventType: ShopEvent, data?: Record<string, unknown>) {
  const event = new CustomEvent(eventType, { detail: data })
  document.dispatchEvent(event)
}

export { on, off, trigger }
