import React from 'react'

import { ShopContext } from '../../../components/context/ShopContext'
import closeIcon from '../../../assets/icons/close-pink-icon.svg'
import styles from './BagPage.module.css'
import formatterPrice from '../../../utils/formatter-price'

interface BagItemListProps {
  name: string
  total_price: number
  id: string
  partner_name: string
}

export default function BagItemList({
  name,
  total_price,
  id,
  partner_name,
}: BagItemListProps) {
  const shopContext = React.useContext(ShopContext)
  return (
    <div className={styles.cartItemContainer}>
      <div className={styles.removeButtonColumn}>
        <div
          className={styles.removeIconContainer}
          onClick={() => shopContext.removeItem(id)}
        >
          <img className={styles.removeIcon} alt="add" src={closeIcon} />
        </div>
      </div>
      <div className={styles.nameColumn}>
        <h3 className={styles.name}>{name}</h3>
        <h4 className={styles.packageLabel}>{partner_name.toUpperCase()}</h4>
      </div>
      <div className={styles.priceColumn}>
        <h3 className={styles.price}>
          {total_price ? formatterPrice(total_price) : ' - '}
        </h3>
      </div>
    </div>
  )
}
