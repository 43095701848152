import React from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { useHistory, generatePath } from 'react-router'
import { useSavingModals } from '@labsavvyapp/ui-components'
import {
  Button,
  ProfilePhoto,
  PasswordStepForm,
  EmailStep,
} from '@labsavvyapp/ui-components'

import style from './ChangePassword.module.css'
import { PROFILE } from '../../../routes/config'
import { PreRecoverEmail } from '../../../graphql/user/mutations'
interface FormPreRegister {
  email: string
  me: any
}

interface IProps {
  me: any
}

function Step1({ me }: IProps) {
  const { push } = useHistory()
  const [preRecoverEmail] = useMutation(PreRecoverEmail)
  const intl = useIntl()

  let nextPath = ''

  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're processing",
    savedMessage: 'Email confirmed',
    errorMessage: 'Email is incorrect',
    callback: (data: FormPreRegister) => {
      nextPath = generatePath(`${PROFILE.confirmToken}?email=${data.email}`)
      return preRecoverEmail({
        variables: {
          email: data.email,
        },
      })
    },
    onSuccess: () => push(nextPath),
  })

  return (
    <main className={style.main}>
      <div className={style.header}>
        <h1 className={style.pageTitle}>Change Password</h1>
        <div className={style.cancelButton}>
          <Button variant="basic" onClick={() => push(PROFILE.base)}>
            Cancel
          </Button>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.avatar}>
          <h1 className={style.avatarTitle}>{me?.name?.display}</h1>
          <ProfilePhoto size={110} url={me?.profile_photo?.url} />
        </div>
        <div className={style.columnFields}>
          <div className={style.passwordStepContainer}>
            <PasswordStepForm
              type="email"
              onSubmit={showModals}
              currentStep="1"
              totalSteps="3"
              intl={intl}
              title="Your Email"
              subTitle="What’s your email?"
            >
              <EmailStep />
            </PasswordStepForm>
          </div>
        </div>
      </div>
      {modals}
    </main>
  )
}

export default Step1
