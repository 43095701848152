import React from 'react'

import { useHistory } from 'react-router'
import classnames from 'classnames'

import style from './BackButton.module.css'
import TriangleIcon from '../Icons/TriangleIcon'

export default function BackButton({ text, to, goBack }) {
  const { push } = useHistory()

  return (
    <a
      className={classnames(style.container)}
      data-test="back-button"
      onClick={() => (goBack ? to : push(to))}
    >
      <TriangleIcon direction="left" color="#d76acd" opacity="1" />
      <div className={style.text}>{text}</div>
    </a>
  )
}
