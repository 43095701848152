import React from 'react'
import { Switch, Route } from 'react-router'

import { SETTINGS } from './config'
import ConnectedProvidersScreen from '../pages/settings/ConnectedProvidersScreen'
import InfoScreen from '../pages/settings/InfoScreen'
import SettingsLayout from '../layouts/Settings/SettingsLayout'
import MainLayout from '../layouts/Main/MainLayout'
import ConnectToProvidersScreen from '../pages/settings/ConnectToProvidersScreen'
import AccountScreen from '../pages/settings/account/AccountPage'

interface ISettingsRoutesProps {
  loggedIn: boolean
  me: any
  path: string
}

export default function SettingsRoutes({
  path,
  me,
  loggedIn,
}: ISettingsRoutesProps) {
  return (
    <Route
      path={path}
      render={() => (
        <Switch>
          <MainLayout loggedIn={loggedIn} me={me}>
            <SettingsLayout>
              <>
                <Route path={SETTINGS.account} component={AccountScreen} />
                <Route
                  exact
                  path={SETTINGS.connectedProviders}
                  component={ConnectedProvidersScreen}
                />
                <Route
                  exact
                  path={SETTINGS.connectedProvidersId}
                  component={ConnectedProvidersScreen}
                />
                <Route exact path={SETTINGS.info} component={InfoScreen} />
                <Route
                  exact
                  path={SETTINGS.connectToProvider}
                  component={ConnectToProvidersScreen}
                />
                <Route
                  exact
                  path={SETTINGS.connectToProviderId}
                  component={ConnectToProvidersScreen}
                />
              </>
            </SettingsLayout>
          </MainLayout>
        </Switch>
      )}
    />
  )
}
