import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'
import { useHistory, generatePath } from 'react-router'

import {
  PasswordStepForm,
  EmailStep,
  useSavingModals,
} from '@labsavvyapp/ui-components'

import style from './ChangePassword.module.css'
import { SIGN } from '../../../routes/config'
import { PreRecoverEmail } from '../../../graphql/user/mutations'
interface FormPreRegister {
  email: string
  me: any
}

interface IProps {
  me: any
}

function Recover({}: IProps) {
  const { push } = useHistory()
  const [preRecoverEmail] = useMutation(PreRecoverEmail)
  const intl = useIntl()

  let nextPath = ''

  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're processing",
    savedMessage: 'Email confirmed',
    errorMessage: 'Email is incorrect',
    callback: (data: FormPreRegister) => {
      nextPath = generatePath(`${SIGN.recoverConfirmToken}?email=${data.email}`)
      return preRecoverEmail({
        variables: {
          email: data.email,
        },
      })
    },
    onSuccess: () => push(nextPath),
  })

  return (
    <main className={style.main}>
      <div className={style.form}>
        <PasswordStepForm
          type="email"
          onSubmit={showModals}
          currentStep="1"
          totalSteps="3"
          intl={intl}
          title="Your Email"
          subTitle="What’s your email?"
        >
          <EmailStep />
        </PasswordStepForm>
      </div>
      {modals}
    </main>
  )
}

export default Recover
