import React from 'react'
import MainLayout from './MainLayout'

const withMainLayout = (
  Component: any,
  { loggedIn, me } = { loggedIn: false, me: {} },
) => (
  <MainLayout loggedIn={loggedIn} me={me}>
    <Component loggedIn={loggedIn} me={me} />
  </MainLayout>
)
export default withMainLayout
