import { useState, useRef, useEffect } from 'react'
import IdleTimer from 'react-idle-timer'
import { SIGN } from '../../routes/config'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { isAuthenticated, removeCookies } from '../auth'
import { YesCancelModal } from '@labsavvyapp/ui-components'

const IdleTimerContainer = () => {
  const location = useLocation()

  const idleTimerRef = useRef(null)
  const sessionTimeoutRef = useRef(null)

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const { push } = useHistory()

  const IDLE_TIMER = 840 * 1000 // 240s = 4 minutes idle time
  const WARNING_TIMER = 60 * 1000 // 60s = 1 minute warning before logout

  const onIdle = () => {
    setSeconds(WARNING_TIMER / 1000)
    setTimeout(() => {
      if (isAuthenticated() && !location.pathname.includes(SIGN.base)) {
        sessionTimeoutRef.current = setTimeout(handleLogout, WARNING_TIMER)
        setShowErrorModal(true)
      }
    }, 1000)
  }

  const handleLogout = () => {
    push(SIGN.out)
    setShowErrorModal(false)
    clearTimeout(sessionTimeoutRef.current)
  }

  const stayActive = () => {
    setSeconds(null)
    setShowErrorModal(false)
    clearTimeout(sessionTimeoutRef.current)
  }

  const [seconds, setSeconds] = useState(null)

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      setErrorMessage(
        `You will be automatically logged out in ${seconds} seconds due to inactivity. Do you want to log out now?`,
      )
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  }, [seconds])

  // force logout if tab/browser is close
  useEffect(() => {
    const isLogin = sessionStorage.getItem('login')

    // check login session
    if (isLogin != 'true') {
      removeCookies()

      // redirect to signin after force logout
      // if (location.pathname != SIGN.in) {
      //   push(SIGN.in)
      // }
    }
  })

  return (
    <>
      <YesCancelModal
        message={errorMessage}
        onCloseClick={stayActive}
        onYesClick={handleLogout}
        open={showErrorModal}
      />
      <IdleTimer
        ref={idleTimerRef}
        timeout={IDLE_TIMER}
        onIdle={onIdle}
      ></IdleTimer>
    </>
  )
}

export default IdleTimerContainer
