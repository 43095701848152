import React from 'react'
import { Icon } from 'semantic-ui-react'

import isAnEnumUnits from '../../../utils/is-an-enum-units'
import capitalize from '../../../utils/capitalize'
import style from './LabResultReferenceInfo.module.css'

export default function LabResultReferenceInfo({ result }) {
  return (
    <div className={style.container} data-test="lab-result">
      <div className={style.testName}>
        <h4 className={style.label}>Test</h4>
        <p className={style.name}>{result && result.name}</p>
      </div>

      {result && result.value && (
        <div className={style.resultRow}>
          <h4 className={style.label}>Patient&apos;s Result</h4>
          <p className={style.name}>
            <Icon
              name={
                result.status === 'normal' ? 'circle' : 'exclamation circle'
              }
              size="small"
              color={
                (result.status === 'normal' && 'green') ||
                (result.status === 'abnormal' && 'red') ||
                'grey'
              }
            />
            {(isAnEnumUnits(result.units) && capitalize(result.value)) ||
              result.value}{' '}
            {!isAnEnumUnits(result.units) && result.units}
          </p>
        </div>
      )}

      {result.refRange && (
        <div className={style.resultRow}>
          <h4 className={style.label}>Reference</h4>
          <p className={style.referenceText}>
            {result.refRange} {result.units}
          </p>
        </div>
      )}
    </div>
  )
}
