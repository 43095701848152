import React from 'react'
import { Receiver } from '../../components/Messaging/MessageList'
import { camelizeKeys } from 'humps'
import { useQuery } from '@apollo/client'

import { GetProfile } from '../../graphql/user/queries'

export default function ReceivedMessage({ contact, message, date }) {
  const { data } = useQuery(GetProfile, {
    skip: !contact,
    variables: {
      id: contact,
    },
  })

  const contactInfo = camelizeKeys(data?.mobileGetMe)

  return (
    <Receiver
      name={contactInfo?.name || { first: '', last: '' }}
      photo={contactInfo?.photo?.url || ''}
      message={message}
      date={date}
    />
  )
}
