import {
  VerticalTabMenu,
  VerticalTabMenuItemLink,
} from '@labsavvyapp/ui-components'

import style from './InfoScreen.module.css'

const InfoScreen = () => {
  interface ILink {
    text: string
    url: string
  }

  const links: ILink[] = [
    {
      text: 'Help & Support',
      url: process.env.REACT_APP_SUPPORT_URL || 'http://labsavvy.com/support',
    },
    {
      text: 'Privacy Policy',
      url:
        process.env.REACT_APP_PRIVACY_POLICY_URL ||
        'https://labsavvy.com/privacy-policy',
    },
    {
      text: 'Terms of Use',
      url:
        process.env.REACT_APP_TERMS_OF_SERVICE ||
        'https://labsavvy.com/terms-of-service',
    },
  ]

  // const handleSelect = (item: ILink) => window.open(item.url, '_blank')

  return (
    <div className={style.informationList}>
      <VerticalTabMenu>
        {links.map((item) => (
          <VerticalTabMenuItemLink
            key={item.text}
            text={item.text}
            url={item.url}
          />
        ))}
      </VerticalTabMenu>
    </div>
  )
}

export default InfoScreen
