import { gql } from '@apollo/client'

const userTypeGql = `
user {
  _id
  name {
    first
    last
  }
  dob
  gender
  emails {
    address
  }
}
`

const tokensGql = `
tokens {
  access
  refresh
}
`

export const Login = gql`
  mutation Login($email: EmailAddress!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        _id
      }
      tokens {
        access
      }
    }
  }
`

export const RegisterEmailUser = gql`
  mutation preRegisterUser($email: EmailAddress!, $partnerCode: String) {
    preRegisterUser(email: $email, partnerCode: $partnerCode)
  }
`

export const ConfirmCodeUser = gql`
  mutation proofUserRegistration($email: EmailAddress!, $token: String!) {
    proofUserRegistration(email: $email, token: $token)
  }
`

export const RegisterUser = gql`
  mutation registerUser(
    $proof: String!
    $email: EmailAddress!
    $password: String!
    $firstName: String!
    $lastName: String!
    $dob: DateTime!
    $gender: Gender!
    $partnerCode: String!
  ) {
    registerUser(
      proof: $proof
      email: $email
      password: $password
      first_name: $firstName
      last_name: $lastName
      dob: $dob
      gender: $gender
      partner_code: $partnerCode
    ) {
      ${userTypeGql}
      ${tokensGql}
    }
  }
`

export const UpdateMe = gql`
  mutation UpdateMe($data: UserInput!) {
    mobileUpdateMe(data: $data) {
      _id
    }
  }
`

export const RecoverEmail = gql`
  mutation recoverEmail(
    $proof: String!
    $email: EmailAddress!
    $newPassword: String!
  ) {
    recoverEmail(proof: $proof, email: $email, newPassword: $newPassword)
  }
`

export const ProofRecoveryEmail = gql`
  mutation proofRecoveryEmail($email: EmailAddress!, $token: String!) {
    proofRecoveryEmail(email: $email, token: $token)
  }
`

export const PreRecoverEmail = gql`
  mutation preRecoverEmail($email: EmailAddress!) {
    preRecoverEmail(media: email, email: $email)
  }
`
