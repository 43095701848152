import React from 'react'
import { useLocation, useHistory } from 'react-router'
import { Form, FormContext } from '@labsavvyapp/ui-components'
import { useApolloClient } from '@apollo/client'
import { GetPartnerInfoByPartnerCode } from '../../graphql/connected-providers/queries'
import style from './AuthLayout.module.css'
import { useIntl } from 'react-intl'
import { SIGN } from '../../routes/config'
import ActionInputField from '../../components/ActionInputField/ActionInputField'
import { useState } from 'react'
import useLocalReducer from '../../hooks/useLocalReducer'

export default function HeaderInfo() {
  const { push } = useHistory()
  const { pathname } = useLocation()

  return (
    <>
      {pathname === SIGN.in && <ProvideCodeInput />}
      {pathname === SIGN.up && (
        <div className={style.signInRightHeaderContainer}>
          <div
            className={style.link}
            onClick={() => push(SIGN.in)}
            data-test="link-sign-in"
          >
            SIGN IN
          </div>
          <ProvideCodeInput />
        </div>
      )}
      {pathname !== SIGN.in && pathname !== SIGN.up && (
        <div
          className={style.link}
          onClick={() => push(SIGN.in)}
          data-test="link-sign-in"
        >
          SIGN IN
        </div>
      )}
    </>
  )
}

const initialState = {
  partner_data: {},
}

export function ProvideCodeInput() {
  const [providerCodeInput] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const [
    // state,
    setState,
    // clearState
  ] = useLocalReducer(
    'partner_data',
    (state: any, action: any) => ({ ...state, ...action }),
    { ...initialState },
  )

  const formData = {
    providerCode: '',
  }

  async function handleFormSubmit(formData: any) {
    try {
      setLoading(true)
      const { data } = await client.query({
        query: GetPartnerInfoByPartnerCode,
        variables: { code: formData.providerCode },
      })
      if (data.mobileGetPartnerInfoByPartnerCode) {
        setState({
          partner_data: {
            ...data.mobileGetPartnerInfoByPartnerCode,
            partner_code: formData.providerCode,
          },
        })
        if (pathname === SIGN.in) {
          push(SIGN.up)
        } else if (pathname === SIGN.up) {
          window.location.reload()
          setLoading(false)
        }
      } else {
        setFormErrors({ providerCode: 'invalid code' })
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      setFormErrors({ providerCode: 'invalid code' })
    }
  }

  const handleResetFormErrors = () => {
    if (Object.keys(formErrors).length > 0) {
      setFormErrors({
        providerCode: null,
      })
    }
  }

  return (
    <>
      {!providerCodeInput ? (
        // <Button
        //   className={style.providerCodeContainer}
        //   onClick={() => setProviderCodeInput(true)}
        // >
        //   I HAVE A PROVIDER CODE
        // </Button>
        <></>
      ) : (
        <Form
          className={style.providerCodeInputFormContainer}
          initialFormData={formData}
          errors={formErrors}
          intl={{
            formatMessage,
          }}
          onSubmit={handleFormSubmit}
        >
          <FormContext.Consumer>
            {() => (
              <ActionInputField
                name="provider"
                onChange={() => handleResetFormErrors()}
                loading={loading}
              />
            )}
          </FormContext.Consumer>
        </Form>
      )}
    </>
  )
}
