import { Switch, Route } from 'react-router'
import { STORE } from './config'
import MainLayout from '../layouts/Main/MainLayout'
import StorePage from '../pages/ecommerce/store/StorePage'
import BagPage from '../pages/ecommerce/bag/BagPage'
import CheckoutPage from '../pages/ecommerce/checkout/CheckoutPage'
import PaymentUpdatePage from '../pages/ecommerce/creditcard/UpdatePaymentPage'
import OrderPlacedPage from '../pages/ecommerce/orderplaced/OrderPlacedPage'
import StoreList from '../pages/ecommerce/store/StoreList'
// import withMainLayout from '../layouts/Main/withMainLayout'

interface ShopRouteProps {
  path: string
  loggedIn: boolean
  me: any
}

export default function StoreRoute({ path, loggedIn, me }: ShopRouteProps) {
  return (
    <Route
      path={path}
      render={({ match }) => (
        <MainLayout me={me} loggedIn={loggedIn}>
          <Switch>
            <Route
              exact
              path={STORE.checkoutCompleted}
              component={OrderPlacedPage}
            />
            <Route exact path={STORE.browse} component={StoreList} />
            <Route
              exact
              path={STORE.checkout}
              render={(props) => <CheckoutPage {...props} me={me} />}
            />
            <Route exact path={STORE.bag} component={BagPage} />
            <Route exact path={STORE.payment} component={PaymentUpdatePage} />
            <Route path={match.path} component={StorePage} />
          </Switch>
        </MainLayout>
      )}
    />
  )
}
