import React from 'react'

import style from './Footer.module.css'

interface Props {
  children: any
}

export default function Footer({ children }: Props) {
  return <footer className={style.container}>{children}</footer>
}

Footer.layoutElementName = 'Footer'
