import React, { useContext, useState, useEffect } from 'react'
import { Popup } from 'semantic-ui-react'

import { useHistory } from 'react-router'
import { ShopContext } from '../context/ShopContext'
import shopBagEmptyIcon from '../../assets/icons/shopping-bag-empty.svg'
import shopBagFullIcon from '../../assets/icons/shopping-bag-full.svg'
import styles from './HeaderBag.module.css'
// import Tooltip from '../tooltip/Tooltip'
import { STORE } from '../../routes/config'
import { on, off } from '../events/shopEvent'

export default function HeaderBag() {
  const history = useHistory()
  const shopContext = useContext(ShopContext)
  const [showTooltip, setShowTooltip] = useState(true)
  const [lastLocation, setLastLocation] = useState(history.location.pathname)
  const size = shopContext.size()

  const goToBag = React.useCallback(() => {
    history.push(STORE.bag)
  }, [])

  const enableTooltip = () => {
    setShowTooltip(true)
  }

  useEffect(() => {
    return history.listen((location) => {
      if (lastLocation !== location.pathname) {
        setShowTooltip(false)
      }
      setLastLocation(location.pathname)
    })
  }, [lastLocation, history])

  useEffect(() => {
    on('newitemadded', enableTooltip)
    return () => {
      off('newitemadded', enableTooltip)
    }
  }, [])

  if (size === 0) {
    if (showTooltip) {
      setShowTooltip(false)
    }
    return (
      <div className={styles.bagFullContainer} onClick={goToBag}>
        <img
          className={styles.bagIcon}
          src={shopBagEmptyIcon}
          alt="Empty bag"
        />
      </div>
    )
  }
  return (
    <Popup
      trigger={
        <div className={styles.bagFullContainer} onClick={goToBag}>
          <span>{`(${size})`}</span>
          <img
            className={styles.bagIcon}
            src={shopBagFullIcon}
            alt="Bag with items"
          />
        </div>
      }
      content="View your cart"
      position="bottom center"
    />
  )
}
