import { useQuery, useMutation } from '@apollo/client'
import { camelizeKeys } from 'humps'
import {
  AddWellnessTeamMember,
  RevokeWellnessTeamMember,
} from '../graphql/messaging/mutations'
import { MobileWellnessTeam } from '../graphql/messaging/queries'

const setConnectedStateOnWellnessTeam = (wellnessTeam) =>
  wellnessTeam.map((partnerWellnessTeam) => ({
    partner: partnerWellnessTeam.partner,
    connected: partnerWellnessTeam.connected.map((contact) => ({
      ...contact,
      connected: true,
    })),
    available: partnerWellnessTeam.available.map((contact) => ({
      ...contact,
      connected: false,
    })),
  }))

export const useWellnessTeam = (
  // wellnessTeamOptions = {},
  queryOptions = {},
) => {
  const {
    data: wellnessTeamData,
    refetch,
    loading,
  } = useQuery(MobileWellnessTeam, queryOptions)

  const wellnessTeam =
    (wellnessTeamData?.mobileWellnessTeam?.wellnessTeam &&
      camelizeKeys(
        setConnectedStateOnWellnessTeam(
          wellnessTeamData?.mobileWellnessTeam?.wellnessTeam,
        ),
      )) ||
    []

  const [revoke] = useMutation(RevokeWellnessTeamMember)
  const [add] = useMutation(AddWellnessTeamMember)

  return {
    wellnessTeam,
    refetch,
    loading,
    revoke: async (wellnessTeamMember) => {
      await revoke({
        variables: {
          membersToRevoke: [wellnessTeamMember.id],
        },
      })
      refetch()
    },
    add: async (wellnessTeamMember) => {
      await add({
        variables: {
          memberToAdd: wellnessTeamMember.id,
        },
      })
      refetch()
    },
  }
}
