import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { Image } from 'semantic-ui-react'

import {
  Button,
  VerticalTabMenu,
  VerticalTabMenuItemCard,
} from '@labsavvyapp/ui-components'

import { useMutation, useQuery } from '@apollo/client'
import { ListPartners } from '../../graphql/connected-providers/queries'
import { DeauthorizeMyPartner } from '../../graphql/connected-providers/mutations'
import { SETTINGS } from '../../routes/config'

import style from './ConnectedProvidersScreen.module.css'
import { PlusIcon } from '../../components/Icons/light'

interface IPartner {
  _id: string
  name: string
  connected: boolean
  logo: {
    _id: string
    url: string
    versions: {
      thumb: string
    }
  }
}

interface IParams {
  id: string
}

const ConnectedProvidersScreen = () => {
  const { id } = useParams<IParams>()
  const { push } = useHistory()
  const { data, refetch } = useQuery(ListPartners)
  const location = useLocation()

  const listPartners: Array<IPartner> = data?.mobileListPartners || []
  const selectedPartner: IPartner = listPartners.filter(
    (partner: IPartner) => partner._id === id,
  )[0]

  const [disconnectPartner] = useMutation(DeauthorizeMyPartner)

  useEffect(() => {
    refetch()
  }, [location])

  const handleDisconnect = async () => {
    await disconnectPartner({ variables: { labId: selectedPartner._id } })
    push(`${SETTINGS.connectedProviders}`)
  }
  const handleSelect = (partner: IPartner) =>
    push(`${SETTINGS.connectedProviders}/${partner._id}`)

  return (
    <>
      {listPartners?.length > 0 ? (
        <div className={style.providersList}>
          <div className={style.addContainer}>
            <div onClick={() => push(SETTINGS.connectToProvider)}>
              <PlusIcon size="lg" className={style.addIcon} />
            </div>
          </div>
          <VerticalTabMenu>
            {listPartners.map((partner: IPartner) => (
              <VerticalTabMenuItemCard
                key={partner._id}
                imageUrl={partner?.logo?.url}
                title={partner.name}
                size={50}
                selected={id === selectedPartner?._id && id}
                onClick={handleSelect}
                item={partner}
              />
            ))}
          </VerticalTabMenu>
        </div>
      ) : (
        <div className={style.emptyStateContainer}>
          <div className={style.emptyStateContent}>
            <h3>You are not connected to any provider!</h3>
            <h4>Please add a connection by clicking below.</h4>
            <div onClick={() => push(SETTINGS.connectToProvider)}>
              <PlusIcon size="lg" className={style.addIcon} />
            </div>
          </div>
        </div>
      )}
      {selectedPartner && (
        <div className={style.providerDetails}>
          <Image src={selectedPartner?.logo?.url} className={style.photo} />
          <h3 className={style.providerName}>{selectedPartner.name}</h3>
          <h4>
            You are connected to this provider, which allows for automatic
            retrieval of current and future lab reports.
          </h4>
          <Button
            variant="secondary"
            className={style.disconnectButton}
            onClick={handleDisconnect}
          >
            Disconnect
          </Button>
        </div>
      )}
    </>
  )
}

export default ConnectedProvidersScreen
