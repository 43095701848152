import classnames from 'classnames'

import style from './LabReportsListPage.module.css'

interface LabReportSummaryProps {
  normal: number
  abnormal: number
}

export default function LabReportSummary({
  normal,
  abnormal,
}: LabReportSummaryProps) {
  return (
    <div className={style.summary}>
      <div className={classnames(style.summaryValue, style.summaryNormal)}>
        {normal}
      </div>
      <div className={classnames(style.summaryValue, style.summaryAbnormal)}>
        {abnormal}
      </div>
    </div>
  )
}
