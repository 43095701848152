import style from './TestReferenceFAQ.module.css'

export interface ResultSummaries {
  whatIsThisTestUsedFor: string
  whatMightAffectTestResults: string
  whatShouldIAskMyClinician: Array<string>
  testSummaries: Array<{ question: string; answer: string }>
}

interface TestReferenceProps {
  aka: string
  resultSummaries: ResultSummaries
}

export default function TestReferenceFAQ({
  resultSummaries,
  aka,
}: TestReferenceProps) {
  const faq = []

  if (resultSummaries.whatIsThisTestUsedFor) {
    faq.push({
      question: 'What is this test used for?',
      answer: resultSummaries.whatIsThisTestUsedFor,
    })
  }

  if (resultSummaries.whatMightAffectTestResults) {
    faq.push({
      question: 'What might affect test results?',
      answer: resultSummaries.whatMightAffectTestResults,
    })
  }

  if (resultSummaries.whatShouldIAskMyClinician.length) {
    faq.push({
      question: 'What should I ask my clinician?',
      answer: resultSummaries.whatShouldIAskMyClinician,
    })
  }

  if (resultSummaries.testSummaries) {
    resultSummaries.testSummaries.map((testSummary) => {
      faq.push({
        question: testSummary.question,
        answer: testSummary.answer,
      })
    })
  }

  if (aka) {
    faq.push({
      question: 'This test is also known as:',
      answer: aka,
    })
  }

  return (
    <div className={style.container}>
      {faq.map(({ question, answer }, index) => (
        <div className={style.questions} key={`faq-${index}`}>
          <h3 className={style.question}>{question}</h3>
          {!Array.isArray(answer) ? (
            <div
              className={style.answer}
              dangerouslySetInnerHTML={{ __html: answer || '' }}
            />
          ) : (
            <ul className={style.answers}>
              {answer.map((answer, index) => (
                <li key={`li-${index}`}>
                  <span
                    className={style.answer}
                    dangerouslySetInnerHTML={{ __html: answer || '' }}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  )
}
