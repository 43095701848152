import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import { useApolloClient } from '@apollo/client'
import { ShopContext } from '../../../components/context/ShopContext'
import { SIGN } from '../../../routes/config'
import { removeCookies } from '../../../utils/auth'
import Loader from '../../../components/loader/Loader'

const DELAY = 1000

export default function SignOutPage() {
  const { push } = useHistory()
  const shopContext = useContext(ShopContext)
  const client = useApolloClient()
  useEffect(() => {
    async function signOut() {
      client.resetStore()
      setTimeout(() => {
        removeCookies()
        push(SIGN.in)
      }, DELAY)
    }
    shopContext.removeAll()
    signOut()
  }, [push])

  return <Loader>Signing out</Loader>
}
