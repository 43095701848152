import React from 'react'
import { matchPath, useLocation, useHistory } from 'react-router'
import { Header, Sidebar, HeaderDropdown } from '@labsavvyapp/ui-components'
import { useQuery } from '@apollo/client'
import { GetSession } from '../../graphql/user/queries'
import { isAuthenticated } from '../../utils/auth'
import style from './MainLayout.module.css'
import {
  HOME,
  LAB_REPORTS,
  SETTINGS,
  SIGN,
  PROFILE,
  // SHOP,
  STORE,
  // WELLNESS_TEAM,
} from '../../routes/config'
import logo from '../../assets//labsavvy-logo.svg'
import laboratoryIcon from '../../assets/icons/laboratory-icon.svg'
import personIcon from '../../assets/icons/person-icon.svg'
// import chatIcon from '../../assets/icons/chat-icon.svg'
// import shoppingBagIcon from '../../assets/icons/shopping-bag.svg'
import HeaderBag from '../../components/headerbag/HeaderBag'
import settingsIcon from '../../assets/icons/settings-icon.svg'
// import orderReceivedIcon from '../../assets/icons/order-received-icon.svg'
import storeIcon from '../../assets/icons/store-icon.svg'

const dropdownItems = [
  {
    name: 'Sign Out',
    url: SIGN.out,
    showWhenLoggedIn: true,
    showDivider: false,
  },
]

const getSidebarItems = (pathname: string) => {
  const items = [
    {
      title: 'Lab Reports',
      icon: laboratoryIcon,
      url: LAB_REPORTS.base,
      active: matchPath(pathname, {
        path: LAB_REPORTS.base,
      }),
    },
    // {
    //   title: `Messaging`,
    //   icon: chatIcon,
    //   url: WELLNESS_TEAM.base,
    //   active: matchPath(pathname, {
    //     path: WELLNESS_TEAM.base,
    //   }),
    // },
    // {
    //   title: `Shop`,
    //   icon: shoppingBagIcon,
    //   url: SHOP.base,
    //   active: matchPath(pathname, {
    //     path: SHOP.base,
    //   }),
    // },
    {
      title: `Shop`,
      icon: storeIcon,
      url: STORE.base,
      active: matchPath(pathname, {
        path: STORE.base,
      }),
    },
    {
      title: `profiles`,
      icon: personIcon,
      url: PROFILE.base,
      active: matchPath(pathname, {
        path: PROFILE.base,
      }),
    },
    {
      title: `settings`,
      icon: settingsIcon,
      url: SETTINGS.base,
      active: matchPath(pathname, {
        path: SETTINGS.base,
      }),
    },
  ]
  return items
}

interface MainLayoutProps {
  children: React.ReactElement
  loggedIn: boolean
  me: any
}

export default function MainLayout({
  children,
  loggedIn,
  me,
}: MainLayoutProps) {
  const ChildrenWithProps = React.cloneElement(children, { loggedIn, me })
  const { data: sessionData } = useQuery(GetSession)
  const session = sessionData && sessionData.session
  const isLoggedIn = session ? session.isLoggedIn : isAuthenticated()

  const { push } = useHistory()
  const { pathname } = useLocation()
  // Navigation
  const handleLinkClick = ({ url }: { url: string }) => {
    if (url) {
      push(url)
    }
  }

  return (
    <div
      className={style.root}
      style={{
        height: pathname.match(/lab-reports.*view/) ? '' : '100%',
      }}
    >
      <Header
        className={style.header}
        logo={{ image: logo, url: isLoggedIn ? HOME : SIGN.in }}
        onLinkClick={() =>
          handleLinkClick({ url: isLoggedIn ? HOME : SIGN.in })
        }
      >
        <div className={style.contentHeader}>
          <HeaderBag />
          <HeaderDropdown
            logged={loggedIn}
            me={me}
            dropdownItems={dropdownItems}
            onLinkClick={handleLinkClick}
          />
        </div>
      </Header>

      <div className={style.contentHolder}>
        <Sidebar
          items={getSidebarItems(pathname)}
          onItemClick={(url: string) => handleLinkClick({ url })}
        />
        <div className={style.content}>{ChildrenWithProps}</div>
      </div>
    </div>
  )
}
