import { gql } from '@apollo/client'

export const DeauthorizeMyPartner = gql`
  mutation MobileDeauthorizeMyPartner($labId: MongoID!) {
    mobileDeauthorizeMyPartner(partner: $labId)
  }
`

export const ConnectWithPartnerCode = gql`
  mutation mobileConnectMeWithPartnerCode($partnerCode: String!) {
    mobileConnectMeWithPartnerCode(partner_code: $partnerCode) {
      _id
      name
      logo {
        _id
        url
        versions {
          small
        }
      }
    }
  }
`
