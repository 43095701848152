import { useContext, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { useStripe } from '@stripe/react-stripe-js'
import { Card } from '../../../components/context/ShopContext'
import { ShopContext, ShopState } from '../../../components/context/ShopContext'
import styles from './Checkout.module.css'

interface PlaceOrderButtonProps {
  showModals: (cart: Cart) => Record<string, unknown>
  card: Card
  clientSecret: string
  paymentIntentId: string
}

export interface Cart {
  payment: { token: string }
  payment_intent_id: string
  items: Array<Record<string, unknown>>
}

export default function PlaceOrderButton({
  card,
  clientSecret,
  paymentIntentId,
  showModals,
}: PlaceOrderButtonProps) {
  const stripe = useStripe()
  const shopContext = useContext(ShopContext)

  const processPayment = async () => {
    try {
      return stripe?.confirmCardPayment(clientSecret)
    } catch (error) {
      throw new Error(`PAYMENT FAILED: ${error}`)
    }
  }

  const [isLoading, setIsLoading] = useState(false)

  const buildCartBody = (products: ShopState): Cart => {
    const cart = {} as Cart
    const storeItems = Object.keys(products).map((id) => ({
      quantity: shopContext.items[id].amount,
      product_id: shopContext.items[id].data._id,
      product_type: 'package',
    }))

    if (card) {
      cart.payment = { token: card.id }
    }

    cart.payment_intent_id = paymentIntentId
    cart.items = storeItems
    return cart
  }

  // workaround for 1s delay?
  const goToPlaceOrder = async () => {
    setIsLoading(true)
    const payment = await processPayment()
    if (payment?.paymentIntent?.id) {
      showModals(buildCartBody(shopContext.items))
    }
  }

  return (
    <Button
      className={styles.checkoutButton}
      disabled={!card || isLoading}
      onClick={goToPlaceOrder}
      color="pink"
      loading={isLoading}
    >
      PLACE ORDER
    </Button>
  )
}
