import React, { useEffect, useState } from 'react'
import { InfiniteScrollList, ListEmpty } from '@labsavvyapp/ui-components'
import { hasMore, isEmpty } from '@labsavvyapp/ui-components/lib/utils'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { GetStoreById } from '../../../graphql/ecommerce/queries'
import styles from './StorePage.module.css'
import StoreItemList from './StoreItemList'

interface StoreListProps {
  storeId: string
}

export default function StoreList() {
  const { storeId } = useParams<StoreListProps>()
  const [items, setItems] = useState([])
  const { data, loading, fetchMore } = useQuery(GetStoreById, {
    fetchPolicy: 'network-only',
    variables: {
      id: storeId,
      page: 1,
      limit: 30,
    },
  })

  useEffect(() => {
    if (data && data.mobileGetStore) {
      const { products } = data.mobileGetStore
      setItems((items) => [...items, ...products])
    }
  }, [data])

  return (
    <div className={styles.storeListContainer}>
      {!loading &&
      (isEmpty(data?.mobileGetStore?.products) ||
        !data?.mobileGetStore?.products) ? (
        <ListEmpty message="No lab products found" />
      ) : (
        <InfiniteScrollList
          scrollableTarget="lab-report-list"
          dataLength={items.length}
          hasMore={hasMore({
            page: data?.mobileGetStore?.page,
            pages: data?.mobileGetStore?.pages,
          })}
          next={() => {
            try {
              fetchMore({
                variables: {
                  page: data?.mobileGetStore?.page + 1,
                  limit: 30,
                },
              })
            } catch (e) {
              console.error(e)
            }
          }}
          masonryLayout={true}
          masonryLayoutColumns={{
            1500: 4,
            1400: 3,
            1000: 2,
            500: 1,
          }}
        >
          {items.length > 0 &&
            items.map((item: any) => {
              return <StoreItemList key={item._id} product={item} />
            })}
        </InfiniteScrollList>
      )}
    </div>
  )
}
