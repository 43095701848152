import React from 'react'

import classnames from 'classnames'
import { useHistory } from 'react-router'
import { Button } from '@labsavvyapp/ui-components'
import styles from './StorePage.module.css'
import commonStyles from '../ShopBase.module.css'
import addIcon from '../../../assets/icons/add-icon.svg'
import { ShopContext, Product } from '../../../components/context/ShopContext'

interface StoreItemListProps {
  product: Product
}

export default function StoreItemList({ product }: StoreItemListProps) {
  const shopContext = React.useContext(ShopContext)
  const { push, location } = useHistory()
  const { marketing } = product
  const hasCoverImage = !!marketing?.cover_image
  const openDetails = () => {
    push(`${location.pathname}/product/${product._id}`, {
      product,
    })
  }
  const IsInTheBag = shopContext.IsInTheBag(product._id)

  return (
    <div className={commonStyles.storeItemContainer}>
      {hasCoverImage ? (
        <div className={commonStyles.itemHeader}>
          <img
            className={commonStyles.coverImage}
            src={marketing.cover_image.url}
          />
          <div
            className={classnames({
              [commonStyles.packageBannerSolid]: !hasCoverImage,
              [commonStyles.packageBannerFade]: hasCoverImage,
            })}
          >
            <h6 className={commonStyles.packageTitle}>LAB TEST PACKAGE</h6>
          </div>
        </div>
      ) : (
        <div
          className={classnames({
            [commonStyles.packageBannerSolid]: !hasCoverImage,
            [commonStyles.packageBannerFade]: hasCoverImage,
          })}
        >
          <h6 className={commonStyles.packageTitle}>LAB TEST PACKAGE</h6>
        </div>
      )}

      <div className={commonStyles.storeMainTitleContainer}>
        <h3 className={commonStyles.titleInline}>{product.name}</h3>
        <h4 className={commonStyles.priceInline}>${product.price}</h4>
      </div>
      <div className={commonStyles.divider} />
      <h5 className={styles.summary}>{marketing?.summary}</h5>
      <div className={styles.actionButtonContainer}>
        <Button
          className={styles.cancelButton}
          variant={Button.variant.basic}
          onClick={openDetails}
          data-test="button-cancel"
        >
          Learn More
        </Button>
        {IsInTheBag ? (
          <div className={commonStyles.inCartLabelContainer}>
            <h4 className={commonStyles.inCartLabel}>Item is in your bag</h4>
          </div>
        ) : (
          <div
            className={styles.plusButtonContainer}
            onClick={() => shopContext.addItem(product)}
          >
            <div className={styles.plusButton}>
              <img className={styles.plusIcon} alt="add" src={addIcon} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
