import React from 'react'
import classnames from 'classnames'

import style from './TriangleIcon.module.css'

const ARROW_UP = 'rotate(270deg)'
const ARROW_RIGHT = 'rotate(deg)'
const ARROW_LEFT = 'rotate(180deg)'
const ARROW_DOWN = 'rotate(90deg)'

function getDirection(direction) {
  switch (direction) {
    case 'down': {
      return ARROW_DOWN
    }
    case 'left':
      return ARROW_LEFT
    case 'right': {
      return ARROW_RIGHT
    }
    default: {
      return ARROW_UP
    }
  }
}

export default function TriangleIcon({
  direction = 'up',
  color = '#000',
  opacity = '0.4',
  className,
}) {
  return (
    <svg
      width="6"
      height="10"
      style={{ transform: getDirection(direction) }}
      xmlns="http://www.w3.org/2000/svg"
      className={classnames(style.icon, className)}
    >
      <path
        d="M6 5l-6 5V0z"
        fill={color}
        fillRule="evenodd"
        fillOpacity={opacity}
      />
    </svg>
  )
}
