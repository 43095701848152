import React from 'react'

import { SanitizedHTML } from '@labsavvyapp/ui-components'
import capitalize from '../../../utils/capitalize'
import style from './FlagStatement.module.css'
import { Test } from './ResultDetails'

const ABNORMAL = 'abnormal'

interface Props {
  result: Test
}

export default function FlagStatement({ result }: Props) {
  const currentYear = new Date().getFullYear()
  const { flagStatement } = result

  if (result.status === ABNORMAL && flagStatement) {
    return flagStatement || result.indicator ? (
      <section className={style.container}>
        <div>
          <b>
            {capitalize(result.indicator).replace(/-/g, ' ')}
            {flagStatement && ': '}
          </b>{' '}
          {flagStatement && <SanitizedHTML text={flagStatement} />}
          <footer className={style.footer}>
            FlagStatement™ by LabSavvy © {currentYear}
          </footer>
        </div>
      </section>
    ) : null
  }

  return null
}
