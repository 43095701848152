export function isValidDate(date: string) {
  const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
  if (isNaN(Date.parse(date)) || !date_regex.test(date)) {
    throw new Error(
      JSON.stringify({
        id: 'invalid_date',
        defaultMessage: 'Invalid date',
      }),
    )
  }
}

export function isFutureDate(date: string) {
  if (Date.parse(date) < Date.now()) {
    throw new Error(
      JSON.stringify({
        id: 'invalid_date',
        defaultMessage: 'Date should be in the future',
      }),
    )
  }
}

export function isPastDate(date: string) {
  if (Date.parse(date) > Date.now()) {
    throw new Error(
      JSON.stringify({
        id: 'invalid_date',
        defaultMessage: 'Date should be in the past',
      }),
    )
  }
}

/**
 *
 * @param date String representation of the Date
 * @throws invalid_date Error if the Date isn't valid or if the date is previous to 1900
 */
export function isValidDoB(date: string) {
  const invalidDateError = new Error(
    JSON.stringify({
      id: 'invalid_date',
      defaultMessage: 'Date should be after the year 1900',
    }),
  )

  try {
    const year = new Date(date).getFullYear()
    if (year < 1900) throw invalidDateError
  } catch (err) {
    throw invalidDateError
  }
}
