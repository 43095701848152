import React from 'react'
import { Switch, Route } from 'react-router'

import { LAB_REPORTS } from './config'
import withMainLayout from '../layouts/Main/withMainLayout'
import LabReportListPage from '../pages/labReports/list/LabReportsListPage'
import LabReportViewPage from '../pages/labReports/view/LabReportViewPage'
import ResultDetails from '../pages/labReports/resultdetails/ResultDetails'

const LabReportsRoutes = ({ path, me, loggedIn }) => (
  <Route
    path={path}
    render={({ match }) => (
      <Switch>
        <Route
          exact
          path={match.path}
          render={() =>
            withMainLayout(LabReportListPage, {
              me,
              loggedIn,
            })
          }
        />
        <Route
          exact
          path={LAB_REPORTS.view}
          render={() =>
            withMainLayout(LabReportViewPage, {
              me,
              loggedIn,
            })
          }
        />
        <Route
          exact
          path={LAB_REPORTS.resultDetails}
          render={() =>
            withMainLayout(ResultDetails, {
              me,
              loggedIn,
            })
          }
        />
      </Switch>
    )}
  />
)

export default LabReportsRoutes
