import React from 'react'
import classnames from 'classnames'

import style from './Panel.module.css'

export default function Panel({ className, size = 1, children }: any) {
  return (
    <section
      className={classnames(style.container, className)}
      style={{ flexGrow: size }}
    >
      {children}
    </section>
  )
}

Panel.layoutElementName = 'Panel'
