import { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { GetCardList } from '../../../graphql/ecommerce/queries'
import { Card } from '../../../components/context/ShopContext'
import styles from './Checkout.module.css'

interface LoadCardBaseInfoProps {
  cardInfo: Card
}

export default function LoadCardBaseInfo({ cardInfo }: LoadCardBaseInfoProps) {
  const [card, setCard] = useState(cardInfo)
  const [getCards, { data }] = useLazyQuery(GetCardList)

  useEffect(() => {
    if (!card) {
      getCards()
    }
  }, [])

  useEffect(() => {
    if (data && data.mobileListPaymentMethods) {
      const defaultCard = data.mobileListPaymentMethods.filter(
        (card: Card) => card.default,
      )
      setCard(defaultCard)
    }
  }, [data])

  return (
    <div>
      <h5 className={styles.paymentTitleCard}>Card Information</h5>
      {card ? (
        <h5 className={styles.paymentCardInfo}>
          {card.brand}
          {' ****'}
          {card.last4}
        </h5>
      ) : null}
    </div>
  )
}
