import { gql } from '@apollo/client'

export const ListPartners = gql`
  query MobileListPartners {
    mobileListPartners(filter: { connected: true }) {
      _id
      name
      connected
      logo {
        _id
        url
        versions {
          thumb
        }
      }
    }
  }
`
export const ListUnconnectedPartners = gql`
  query MobileListPartners {
    mobileListPartners(filter: { connected: false }) {
      _id
      name
      connected
      logo {
        _id
        url
        versions {
          thumb
        }
      }
    }
  }
`

export const GetPartnerInfoByPartnerCode = gql`
  query mobileGetPartnerInfoByPartnerCode($code: String!) {
    mobileGetPartnerInfoByPartnerCode(partner_code: $code) {
      name
      use_co_branding
      project_logo {
        _id
        url
      }
    }
  }
`
