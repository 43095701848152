import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import {
  Form,
  FormContext,
  SubmitButton,
  EmailInputField,
  PasswordInputField,
} from '@labsavvyapp/ui-components'
import { Grid } from 'semantic-ui-react'
import { useApolloClient, useMutation } from '@apollo/client'
import { Login } from '../../../graphql/user/mutations'
import { GetSession } from '../../../graphql/user/queries'
import { setCookie } from '../../../utils/auth'
import { SIGN, HOME } from '../../../routes/config'
import { ConnectWithPartnerCode } from '../../../graphql/connected-providers/mutations'
import useLocalReducer from '../../../hooks/useLocalReducer'
import styles from './SignInPage.module.css'

interface UserAuthBasic {
  email: string
  password: string
}

function SignInPage() {
  const [formErrors, setFormErrors] = React.useState({})
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const client = useApolloClient()
  const [connectWithPartnerCode] = useMutation(ConnectWithPartnerCode)
  const [
    state,
    // setState,
    clearState,
  ] = useLocalReducer('partner_data', (state: any, action: any) => ({
    ...state,
    ...action,
  }))
  const [login] = useMutation(Login, {
    update(cache, { data }) {
      const { tokens } = data.login
      setCookie(tokens.access)

      cache.writeQuery({
        query: GetSession,
        data: {
          session: {
            __typename: 'Session',
            token: tokens.access,
            isLoggedIn: true,
          },
        },
      })
    },
  })
  const onLogin = async ({ email, password }: UserAuthBasic) => {
    try {
      const afterLogin = () => {
        client.resetStore()
        clearState()
        push(HOME)
      }
      await login({ variables: { email, password } })
      if (state.partner_data?.partner_code) {
        connectWithPartnerCode({
          variables: { partnerCode: state.partner_data.partner_code },
        })
          .then(() => {
            afterLogin()
          })
          .catch(() => {
            afterLogin()
          })
      } else {
        afterLogin()
      }
    } catch (error) {
      if (error?.graphQLErrors?.[0]?.message) {
        const serverError = new Error(
          JSON.stringify({
            id: 'server_error',
            defaultMessage: error.graphQLErrors[0].message,
          }),
        )
        setFormErrors({
          email: serverError,
          password: serverError,
        })
      }
    }
  }

  const handleResetFormErrors = () => {
    if (Object.keys(formErrors).length > 0) {
      setFormErrors({
        email: null,
        password: null,
      })
    }
  }

  const clearSignUpData = () => {
    localStorage.removeItem('selectedPartner')
    localStorage.removeItem('partnerCode')
  }

  return (
    <div className={styles.root}>
      <div className={styles.formContainer}>
        <h1 className={styles.signInTitle}>Sign In</h1>
        <>
          <div className={styles.subtitleContainer}>
            Need a LabSavvy account?{' '}
            <Link
              className={styles.createAccountLink}
              to={SIGN.providerConnect}
              onClick={clearSignUpData}
            >
              Create an account
            </Link>
          </div>
          <Form
            onSubmit={onLogin}
            errors={formErrors}
            intl={{
              formatMessage,
            }}
          >
            <FormContext.Consumer>
              {() => (
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <EmailInputField
                        name="email"
                        inputClassName={styles.inputIcon}
                        inputType="underline"
                        placeholder="Your Email"
                        onChange={() => handleResetFormErrors()}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <PasswordInputField
                        type="password"
                        name="password"
                        placeholder="Your Password"
                        inputType="underline"
                        onChange={() => handleResetFormErrors()}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <div className={styles.actionContainer}>
                        <SubmitButton>Sign in</SubmitButton>
                        <Link
                          className={styles.forgotPasswordLink}
                          to={SIGN.recover}
                        >
                          FORGOT PASSWORD
                        </Link>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </FormContext.Consumer>
          </Form>
        </>
      </div>
    </div>
  )
}

export default SignInPage
