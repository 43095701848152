import React from 'react'
import { Switch, Route } from 'react-router'
import { SIGN } from './config'
import AuthLayout from '../layouts/Auth/AuthLayout'
import SignInPage from '../pages/auth/signin/SignInPage'
import HelloScreen from '../pages/auth/signup/HelloScreen'
import PreRegisterEmailScreen from '../pages/auth/signup/PreRegisterEmailScreen'
import VerificationCodeScreen from '../pages/auth/signup/VerificationCodeScreen'
import ProfileCreationScreen from '../pages/auth/signup/ProfileCreationScreen'
import PasswordCreationScreen from '../pages/auth/signup/PasswordCreationScreen'
import SignOutPage from '../pages/auth/signout/SignOutPage'
import Recover from '../pages/auth/recoverPassword/Recover'
import RecoverConfirmToken from '../pages/auth/recoverPassword/RecoverConfirmToken'
import RecoverNewPassword from '../pages/auth/recoverPassword/RecoverNewPassword'
import ProviderConnectScreen from '../pages/auth/signup/ProviderConnectScreen'

interface AuthRoutesProps {
  path: string
}

export default function AuthRoutes({ path }: AuthRoutesProps) {
  return (
    <Route
      path={path}
      render={() => (
        <AuthLayout>
          <Switch>
            <Route exact path={SIGN.in} component={SignInPage} />
            <Route exact path={SIGN.out} component={SignOutPage} />
            <Route
              exact
              path={SIGN.providerConnect}
              component={ProviderConnectScreen}
            />
            <Route exact path={SIGN.up} component={HelloScreen} />
            <Route
              exact
              path={SIGN.upEmail}
              component={PreRegisterEmailScreen}
            />
            <Route
              exact
              path={SIGN.upConfirmToken}
              component={VerificationCodeScreen}
            />
            <Route
              exact
              path={SIGN.upProfileInfo}
              component={ProfileCreationScreen}
            />
            <Route
              exact
              path={SIGN.upSetupPasword}
              component={PasswordCreationScreen}
            />
            <Route exact path={SIGN.recover} component={Recover} />
            <Route
              exact
              path={SIGN.recoverConfirmToken}
              component={RecoverConfirmToken}
            />
            <Route
              exact
              path={SIGN.recoverNewPassword}
              component={RecoverNewPassword}
            />
          </Switch>
        </AuthLayout>
      )}
    />
  )
}
