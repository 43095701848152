import React from 'react'
import { Switch, Route } from 'react-router'
import { EASY_LAB_ACCESS } from './config'
import EasyLabAccessPage from '../pages/easyLabAccess/landing/EasyLabAccessPage'

interface EasyLabAccessRoutesProps {
  path: string
}

export default function EasyLabAccessRoutes({
  path,
}: EasyLabAccessRoutesProps) {
  return (
    <Route
      path={path}
      render={() => (
        <Switch>
          <Route
            exact
            path={EASY_LAB_ACCESS.access}
            component={EasyLabAccessPage}
          />
        </Switch>
      )}
    />
  )
}
