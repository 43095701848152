import React from 'react'

import { useHistory } from 'react-router'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button } from '@labsavvyapp/ui-components'
import styles from './UpdatePayment.module.css'

interface CardFormProps {
  onSave: (sourceId: string) => Record<string, never>
}

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#0a0a0a',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
}

export default function CardForm({ onSave }: CardFormProps) {
  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()

  const onSaveClicked = async () => {
    const cardElem = elements?.getElement(CardElement)

    if (cardElem && stripe) {
      const { source, error } = await stripe.createSource(cardElem, {
        type: 'card',
      })

      if (source) {
        onSave(source.id)
      } else {
        console.log('Source - ERROR: ', error)
      }
    }
  }

  const onCancelClicked = () => {
    history.goBack()
  }

  return (
    <>
      <div className={styles.columnCard}>
        <CardElement options={CARD_OPTIONS} />
      </div>

      <div className={styles.actionButtonContainer}>
        <Button
          className={styles.saveButton}
          variant={Button.variant.basic}
          onClick={onCancelClicked}
        >
          CANCEL
        </Button>
        <Button
          className={styles.saveButton}
          disabled={!stripe}
          onClick={onSaveClicked}
        >
          SAVE
        </Button>
      </div>
    </>
  )
}
