const SIGN_BASE = '/sign'
export const SIGN = {
  base: SIGN_BASE,
  in: `${SIGN_BASE}/in`,
  out: `${SIGN_BASE}/out`,
  profile: `${SIGN_BASE}/profile`,
  recover: `${SIGN_BASE}/recover`,
  recoverConfirmToken: `${SIGN_BASE}/recover/confirm-token`,
  recoverNewPassword: `${SIGN_BASE}/recover/new-password`,
  up: `${SIGN_BASE}/up`,
  upPartnerCode: `${SIGN_BASE}/up?partner-code=:partnerCode`,
  upEmail: `${SIGN_BASE}/up/email`,
  upConfirmToken: `${SIGN_BASE}/up/confirm-token`,
  upProfileInfo: `${SIGN_BASE}/up/profile-info`,
  upSetupPasword: `${SIGN_BASE}/up/setup-password`,
  providerConnect: `${SIGN_BASE}/up/provider`,
}

const EASY_LAB_ACCESS_BASE = '/manage-results'
export const EASY_LAB_ACCESS = {
  base: EASY_LAB_ACCESS_BASE,
  access: `${EASY_LAB_ACCESS_BASE}/access`,
}

const LAB_REPORTS_BASE = '/lab-reports'
export const LAB_REPORTS = {
  base: LAB_REPORTS_BASE,
  view: `${LAB_REPORTS_BASE}/:labReportId/view`,
  resultDetails: `${LAB_REPORTS_BASE}/:labReportId/result/:resultId`,
}
const WELLNESS_TEAM_BASE = '/wellness-team'
export const WELLNESS_TEAM = {
  base: WELLNESS_TEAM_BASE,
  chat: `${WELLNESS_TEAM_BASE}/:chatId`,
}

const SETTINGS_BASE = '/settings'
export const SETTINGS = {
  base: SETTINGS_BASE,
  account: `${SETTINGS_BASE}/account`,
  paymentMethod: `${SETTINGS_BASE}/account/payment-method`,
  transactions: `${SETTINGS_BASE}/account/transactions`,
  connectedProviders: `${SETTINGS_BASE}/connected-providers`,
  connectedProvidersId: `${SETTINGS_BASE}/connected-providers/:id`,
  connectToProvider: `${SETTINGS_BASE}/connect`,
  connectToProviderId: `${SETTINGS_BASE}/connect/:id`,
  info: `${SETTINGS_BASE}/info`,
}

const STORE_BASE = '/shop'
export const STORE = {
  base: STORE_BASE,
  browse: `${STORE_BASE}/browse`,
  checkout: `${STORE_BASE}/checkout`,
  bag: `${STORE_BASE}/cart`,
  payment: `${STORE_BASE}/payment`,
  checkoutCompleted: `${STORE_BASE}/checkout-completed`,
}

// const SHOP_BASE = '/shop'
// export const SHOP = {
//   base: SHOP_BASE,
//   store: `${SHOP_BASE}/:storeId`,
//   productDetails: `${SHOP_BASE}/:storeId/product/:productId`,
//   bag: `${SHOP_BASE}/cart`,
//   payment: `${SHOP_BASE}/payment`,
//   checkout: `${SHOP_BASE}/checkout`,
//   checkoutCompleted: `${SHOP_BASE}/checkout-completed`,
// }

const PROFILE_BASE = '/profile'
export const PROFILE = {
  base: PROFILE_BASE,
  edit: `${PROFILE_BASE}/edit`,
  email: `${PROFILE_BASE}/email`,
  confirmToken: `${PROFILE_BASE}/confirmToken`,
  changePassword: `${PROFILE_BASE}/change-password`,
}

export const HOME = LAB_REPORTS.base
