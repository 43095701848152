import React from 'react'

import style from './PageHeader.module.css'

interface Props {
  title: string
}

export default function Header({ title }: Props) {
  return (
    <header className={style.header}>
      <h1 className={style.title}>{title}</h1>
    </header>
  )
}
