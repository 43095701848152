import { gql } from '@apollo/client'

interface EcommerceFragment {
  products: any
}

const Ecommerce: EcommerceFragment = {
  products: {
    packages: gql`
      fragment PackageProduct on PackageProduct {
        _id
        name
        price
        marketing {
          cover_image {
            _id
            url
          }
          subtitle
          summary
          details
        }
      }
    `,
  },
}

export const GetStores = gql`
  query mobileListStores($page: NonNegativeInt = 1) {
    mobileListStores(limit: 20, page: $page) {
      stores {
        _id
        name
      }
      page
      pages
      total
    }
  }
`

export const GetStoreById = gql`
  query mobileGetStore($id: MongoID!) {
    mobileGetStore(id: $id) {
      _id
      name
      logo {
        _id
        name
        url
      }
      products {
        ...PackageProduct
      }
    }
  }
  ${Ecommerce.products.packages}
`

export const GetStoreProductById = gql`
  query mobileGetProduct($id: MongoID!) {
    mobileGetProduct(id: $id) {
      ...PackageProduct
    }
  }
  ${Ecommerce.products.packages}
`

export const GetCardList = gql`
  query mobileListPaymentMethods {
    mobileListPaymentMethods {
      id
      last4
      brand
      default
    }
  }
`

export const ListTransactions = gql`
  query mobileListTransactions {
    mobileListTransactions(limit: 20, page: 1) {
      transactions {
        payment_intent_id
        products
        amount
        date
        lab_order_id
        lab_report_name
        lab_order_status
        provider_id
        provider_name
        partner_name
      }
    }
  }
`

export const GetAllProducts = gql`
  query mobileGetLabOrderProducts {
    mobileGetLabOrderProducts {
      _id
      name
      partner_id
      project_id
      patient_id
      order_codes
      order_status
      created_at
      updated_at
      patient_name
      partner_name
      package_name
      total_price
    }
  }
`
