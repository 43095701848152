import { useState, useEffect } from 'react'
import { ListLoader } from '@labsavvyapp/ui-components'
import { Image } from 'semantic-ui-react'
import { useQuery } from '@apollo/client'
import styles from './StorePage.module.css'
import ProductCard from '../productcard/ProductCard'

import {
  // GetStores,
  // GetStoreById,
  GetAllProducts,
} from '../../../graphql/ecommerce/queries'

// interface StoreMenuItemProps {
//   name: string
//   _typename: string
//   _id: string
// }

export default function StorePage() {
  const DEFAULT_MENU_LINK = {
    name: 'Labs Pending for Purchase',
  }
  // const [retrigger, setRetrigger] = useState(false)
  // const [storeId, setStoreId] = useState<string>()
  // const [patientId, setPatientId] = useState<string>()
  const [products, setProducts] = useState<any[]>([])
  const [currentStore, setCurrentStore] = useState<any>(DEFAULT_MENU_LINK)

  /*
   * Queries
   */

  // const { data: storeData } = useQuery(GetStores)
  const { data: productData, loading } = useQuery(GetAllProducts, {
    fetchPolicy: 'network-only',
  })

  // returning bad request error
  // const { data } = useQuery(GetStoreById, {
  //   fetchPolicy: 'network-only',
  //   variables: {
  //     id: storeId && storeId,
  //     page: 1,
  //     limit: 30,
  //   },
  // })

  useEffect(() => {
    if (productData && productData.mobileGetLabOrderProducts) {
      // console.log('processing', productData.mobileGetLabOrderProducts)
      setProducts(productData.mobileGetLabOrderProducts)
    }
  }, [productData])

  // useEffect(() => {
  //   if (data && data.mobileGetStore) {
  //     const { products, logo, name, _id } = data.mobileGetStore
  //     // setItems((items) => [...items, ...products])
  //     setProducts(products)
  //     setCurrentStore({
  //       logo: logo,
  //       name: name,
  //       id: _id,
  //     })
  //   }
  //   setRetrigger(false)
  // }, [data, retrigger])

  /*
   * Components
   */

  // const StoreMenuItem = ({ store }: { store: StoreMenuItemProps }) => {
  //   const isActive = store._id === currentStore?.id
  //   return (
  //     <div
  //       className={isActive ? styles.shopMenuActive : styles.shopMenu}
  //       onClick={() => {
  //         setStoreId(store._id)
  //         loadPackageProducts()
  //       }}
  //     >
  //       {store.name}
  //       <div style={{ float: 'right', paddingRight: 10 }}>
  //         <Icon name="angle right" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  /*
   * Helpers
   */

  // const loadPackageProducts = () => {
  //   setRetrigger(true)
  // }

  const loadLabsPendingForPurchase = () => {
    setCurrentStore(DEFAULT_MENU_LINK)

    //load pending for purchase products once api is available
    setProducts(productData.mobileGetLabOrderProducts)
  }

  return (
    <>
      <div className={styles.root}>
        {/* <PageHeader title="Shop" /> */}
        <div className={styles.shopNavigationContainer}>
          <div className={styles.listMenuHeader}>Shop</div>
          <div
            className={
              !currentStore?.name.includes(DEFAULT_MENU_LINK.name)
                ? styles.shopMenu
                : styles.shopMenuActive
            }
            onClick={() => {
              loadLabsPendingForPurchase()
            }}
          >
            Labs Pending for Purchase
          </div>
          {/* {storeData?.mobileListStores?.stores?.map(
            (store: { _id: string }) => {
              return <StoreMenuItem store={store} key={store._id} />
            },
          )} */}
        </div>
        <div className={styles.shopContainer}>
          <div className={styles.mainHeaderContainer}>
            <div className={styles.mainHeaderText}>
              {currentStore?.logo?.url ? (
                <Image src={currentStore?.logo?.url} size="medium" />
              ) : (
                <p>{currentStore?.name}</p>
              )}
            </div>
          </div>

          {loading ? (
            <div style={{ marginTop: 20, width: '98%' }}>
              <ListLoader fetched={0} total={0} loading={loading} />
            </div>
          ) : (
            <div className={styles.productsRow}>
              {products?.length > 0 &&
                products
                  .slice(0)
                  .reverse()
                  .map((product) => {
                    return <ProductCard product={product} key={product._id} />
                  })}
            </div>
          )}

          {!loading && products?.length < 1 && (
            <div className={styles.mainHeaderContainer}>
              <b>No packages found at the moment.</b> If you feel this is an
              error, you may try to{' '}
              <a
                style={{
                  color: '#D76ACD',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => window.location.reload()}
              >
                refresh
              </a>{' '}
              page - this may be a momentary issue.
            </div>
          )}
        </div>
      </div>
    </>
  )
}
