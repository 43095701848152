import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useQuery, useLazyQuery } from '@apollo/client'

import {
  LabReportTestResult,
  EasyLabAccessCard,
  Center,
  Flex,
  PolkaBg,
  InvalidExpiredTokenPage,
  ReportDetails,
  Sticky,
  WhoopsPage,
  Button,
} from '@labsavvyapp/components'

import styles from './EasyLabAccessPage.module.css'
import {
  GetLabResultViaToken,
  VerifyLabResultToken,
} from '../../../graphql/easyLabAccess/queries'
import LabReportNotes from '../../labReports/view/LabReportNotes'
import LabReportResultCategory from '../../labReports/view/LabReportResultCategory'
import { SIGN } from '../../../routes/config'

interface GetLabResultViaTokenDataProps {
  patient_last_name: string
  patient_dob: Date
}

interface LabResultProps {
  name: string
  subtitle: string
  summary: string
  icon: { url: string }
  details: string
  tests: any
}

function EasyLabAccessPage() {
  // const { push } = useHistory()

  /// Get data from form
  const [data, setData] = useState<GetLabResultViaTokenDataProps>()
  const [sessionData, setSessionData] =
    useState<GetLabResultViaTokenDataProps>()

  /// Get access token
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')

  // Responsiveness
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 768px)').matches,
  )

  useEffect(() => {
    window
      .matchMedia('(min-width: 768px)')
      .addEventListener('change', (e) => setMatches(e.matches))
  }, [])

  // Access patient data on page load if available
  useEffect(() => {
    const storedData = localStorage.getItem('easyAccess')
    const storedToken = localStorage.getItem('easyAccess_token')
    if (storedToken) {
      if (token == JSON.parse(storedToken)) {
        if (storedData) {
          setSessionData(JSON.parse(storedData))
        }
      } else {
        localStorage.removeItem('easyAccess')
        localStorage.removeItem('easyAccess_token')
      }
    }
  }, [])

  // Refetch results once patient data is found
  useEffect(() => {
    if (sessionData) {
      runQuery({
        variables: {
          labResultInput: {
            token: token,
            patient_last_name: sessionData.patient_last_name,
            patient_dob: sessionData.patient_dob,
          },
        },
      })
    }
  }, [sessionData])

  // Verify access token validity
  const {
    data: verifyLabResultTokenData,
    loading: verifyLabResultTokenLoading,
    error: verifyLabResultTokenError,
  } = useQuery(VerifyLabResultToken, {
    variables: { token: token },
  })

  // Store patient data as session in case of browser refresh
  const saveToSession = () => {
    localStorage.setItem('easyAccess', JSON.stringify(data))
    localStorage.setItem('easyAccess_token', JSON.stringify(token))
  }

  // Get lab result
  const [
    runQuery,
    { loading: getLabResultViaTokenLoading, data: getLabResultViaTokenData },
  ] = useLazyQuery(GetLabResultViaToken)

  const viewResults = () => {
    saveToSession()
    if (data) {
      runQuery({
        variables: {
          labResultInput: {
            token: token,
            patient_last_name: data.patient_last_name,
            patient_dob: data.patient_dob,
          },
        },
      })
    }
  }

  const getInvalidExpiredTokenPage = () => {
    return (
      <InvalidExpiredTokenPage
        expiryTime={48}
        header2mt={100}
        logoOffsetLeft={45}
        loginLink={SIGN.in}
        createAccountLink={SIGN.providerConnect}
      />
    )
  }

  if (!token) {
    return getInvalidExpiredTokenPage()
  }

  if (!verifyLabResultTokenLoading) {
    const isTokenInvalid = verifyLabResultTokenError ? true : false
    if (isTokenInvalid) {
      return getInvalidExpiredTokenPage()
    }
    const isTokenExpired =
      !verifyLabResultTokenData.verifyLabResultToken?.status

    if (isTokenExpired) {
      return getInvalidExpiredTokenPage()
    }
  }

  console.log('getLabResultViaTokenData', getLabResultViaTokenData)

  if (getLabResultViaTokenData) {
    if (getLabResultViaTokenData.getLabResultViaToken === null) {
      return (
        <WhoopsPage
          errorMessage="Your lab results are not yet ready "
          actionMessage={
            <Button
              display="inline"
              onClick={() => {
                localStorage.removeItem('easyAccess')
                localStorage.removeItem('easyAccess_token')
                window.location.reload()
              }}
              radius="xl"
              size="md"
              uppercase
              variant="default"
            >
              Try Again
            </Button>
          }
          logoOffsetLeft={46}
          header2mt={100}
        />
      )
    }
    return (
      <div className={styles.container}>
        <Flex
          bg={'#f8f8f8'}
          align="flex-start"
          direction={{ base: 'column', sm: 'row' }}
          justify="flex-start"
          wrap="wrap"
        >
          <Sticky
            offsetTop={20}
            offsetBottom={20}
            className={styles.leftPanel}
            disabled={!matches}
          >
            <div className={styles.panelContents}>
              <ReportDetails
                reportName={getLabResultViaTokenData.getLabResultViaToken.name}
                id={getLabResultViaTokenData.getLabResultViaToken._id}
                shortId={getLabResultViaTokenData.getLabResultViaToken.short_id}
                status={getLabResultViaTokenData.getLabResultViaToken.status}
                summary={getLabResultViaTokenData.getLabResultViaToken.summary}
                providerName={
                  getLabResultViaTokenData.getLabResultViaToken.provider.name
                }
                createdAt={
                  getLabResultViaTokenData.getLabResultViaToken.created_at
                }
              />
              <div className={styles.notesContainer}>
                <LabReportNotes
                  notes={
                    getLabResultViaTokenData.getLabResultViaToken.patient_notes
                  }
                />
              </div>
            </div>
          </Sticky>

          <div className={styles.rightPanel}>
            <div className={styles.panelContents}>
              {getLabResultViaTokenData.getLabResultViaToken.results.map(
                (result: LabResultProps, key: any) => (
                  <LabReportResultCategory
                    key={key}
                    name={result.name}
                    subtitle={result.subtitle}
                    summary={result.summary}
                    image={result.icon ? result.icon.url : ''}
                    style={{ paddingBottom: '20px' }}
                    details={result.details}
                  >
                    {result.tests && (
                      <LabReportTestResult
                        tests={result.tests}
                        paddingInBetween={30}
                      />
                    )}
                  </LabReportResultCategory>
                ),
              )}
            </div>
          </div>
        </Flex>
      </div>
    )
  }
  return (
    <PolkaBg>
      <Center>
        <div className={styles.column}>
          <EasyLabAccessCard
            maxWidth={350}
            onClick={viewResults}
            setData={setData}
            loading={getLabResultViaTokenLoading}
          />
        </div>
      </Center>
    </PolkaBg>
  )
}

export default EasyLabAccessPage
