import React from 'react'
import { Popup } from 'semantic-ui-react'
import * as styles from './ProductCard.module.css'
import formatterPrice from '../../../utils/formatter-price'

import { ShopContext } from '../../../components/context/ShopContext'

const ProductCard = ({ product }) => {
  const shopContext = React.useContext(ShopContext)

  const IsInTheBag = shopContext.IsInTheBag(product._id)

  const truncateTitle = (input) => {
    const TITLE_LENGTH = 40
    if (input.length > TITLE_LENGTH) {
      return input.substring(0, TITLE_LENGTH) + '...'
    }
    return input
  }

  return (
    <div className={styles.cardContainer}>
      <p
        style={{
          color: '#fff',
          fontWeight: 200,
          fontSize: 10,
        }}
      >
        {product?.partner_name}
      </p>

      <Popup
        trigger={<p className={styles.title}>{truncateTitle(product?.name)}</p>}
        content={product?.name}
        position="top center"
      />

      {/* <p className={styles.description}>For overall health description here</p> */}

      <div style={{ position: 'absolute', bottom: 0 }}>
        <div className={styles.productPrice}>
          {formatterPrice(product?.total_price)}
        </div>
        {IsInTheBag ? (
          <Popup
            trigger={<div className={styles.inCartIcon}>✓</div>}
            content="In your cart"
            position="bottom center"
          />
        ) : (
          <Popup
            trigger={
              <div
                className={styles.addToCartIcon}
                onClick={() => shopContext.addItem(product)}
              >
                +
              </div>
            }
            content="Add to cart"
            position="bottom center"
          />
        )}
      </div>
    </div>
  )
}

export default ProductCard
