import React from 'react'
import { Icon } from 'semantic-ui-react'

import style from './Loader.module.css'

interface LoaderProps {
  children: string
}

export default function Loader({ children }: LoaderProps) {
  return (
    <div className={style.container}>
      <Icon name="spinner" loading size="big" />
      {children && <div className={style.text}>{children}</div>}
    </div>
  )
}
