import React from 'react'

import style from './Nav.module.css'

interface Props {
  children: any
}

export default function Nav({ children }: Props) {
  return <section className={style.container}>{children}</section>
}

Nav.layoutElementName = 'Nav'
