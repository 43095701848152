// import { useState } from 'react'
import { debounce } from 'lodash'
import { Search } from 'semantic-ui-react'

import style from './index.module.css'
import ContactCard from '../ContactCard'

export default function ContactList({
  contactList,
  // loading,
  // loader,
  onContactClick,
  onSearchChange,
  selectedContact,
}) {
  const debouncedOnSearchChange = debounce(onSearchChange, 300)
  return (
    <section className={style.container}>
      <Search
        className={style.search}
        onSearchChange={(_, { value: search }) =>
          debouncedOnSearchChange(search)
        }
        placeholder="Search Team Members"
        showNoResults={false}
      />
      <ul className={style.list}>
        {contactList.map((partnerWellnessTeam) => (
          <div key={partnerWellnessTeam.partner.id}>
            <h3 className={style.titlePartner}>
              {partnerWellnessTeam.partner.name}
            </h3>
            {partnerWellnessTeam.connected.map((contact) => (
              <li key={contact.id} className={style.itemItem}>
                {
                  <ContactCard
                    contact={contact}
                    unread={0}
                    onClick={onContactClick}
                    selected={selectedContact?.id === contact.id}
                  />
                }
              </li>
            ))}
            {(partnerWellnessTeam.available.length && (
              <h4 className={style.availableTeam}>AVAILABLE TEAM MEMBERS</h4>
            )) ||
              null}
            {partnerWellnessTeam.available.map((contact) => (
              <li key={contact.id} className={style.itemItem}>
                {
                  <ContactCard
                    contact={contact}
                    unread={0}
                    onClick={onContactClick}
                    selected={selectedContact?.id === contact.id}
                  />
                }
              </li>
            ))}
          </div>
        ))}
      </ul>
    </section>
  )
}
