import gql from 'graphql-tag'

export const UploadImage = gql`
  mutation UploadImage($file: Upload!) {
    uploadImage(file: $file) {
      _id
      name
    }
  }
`
