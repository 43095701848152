import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'

import {
  Modal,
  useSavingModals,
  PasswordStepForm,
  ResetCodeStep,
} from '@labsavvyapp/ui-components'

import {
  PreRecoverEmail,
  ProofRecoveryEmail,
} from '../../../graphql/user/mutations'
import style from './ChangePassword.module.css'
import { SIGN } from '../../../routes/config'

interface FormPreRegister {
  email: string
  token: string
  me: any
}

interface IProps {
  me: any
}

function RecoverConfirmToken({}: IProps) {
  const { push } = useHistory()
  const { search } = useLocation()
  const [modalSubmitCodeOpen, setModalSubmitCodeOpen] = useState(false)
  const [preRecoverEmail] = useMutation(PreRecoverEmail)
  // const [modalErrorCodeOpen, setModalErrorCodeOpen] = useState(false)
  const [proofRecoveryEmail] = useMutation(ProofRecoveryEmail)
  const intl = useIntl()

  const queryParams = new URLSearchParams(search)
  const email = queryParams.get('email')

  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're processing",
    savedMessage: 'The 6-digit code is valid',
    errorMessage: 'The 6-digit code is invalid',
    callback: (data: FormPreRegister) =>
      proofRecoveryEmail({
        variables: {
          email: email,
          token: data.token,
        },
      }),
    onSuccess: ({
      data: { proofRecoveryEmail },
    }: {
      data: { proofRecoveryEmail: string }
    }) => {
      push(
        `${SIGN.recoverNewPassword}?proof=${proofRecoveryEmail}&email=${email}`,
      )
    },
  })

  const resendCode = async () => {
    setModalSubmitCodeOpen(true)
    await preRecoverEmail({ variables: { email } })
    setModalSubmitCodeOpen(false)
  }

  return (
    <main className={style.main}>
      <div className={style.form}>
        <Modal
          onCloseClick={() => setModalSubmitCodeOpen(false)}
          open={modalSubmitCodeOpen}
        >
          {`Resending code to ${email} `}
        </Modal>
        <PasswordStepForm
          type="reset"
          onSubmit={showModals}
          currentStep="2"
          totalSteps="3"
          intl={intl}
          title="Your Code"
          subTitle="Check your email for a 6-digit code to verify your
                account."
        >
          <ResetCodeStep resendCode={resendCode} />
        </PasswordStepForm>
      </div>
      {modals}
    </main>
  )
}

export default RecoverConfirmToken
