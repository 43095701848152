import React, { useEffect } from 'react'
import { Icon } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import { LAB_REPORTS } from '../../../routes/config'
import styles from './OrderPlacedPage.module.css'

export default function OrderPlacedPage() {
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener('popstate', () => {
      window.history.pushState(null, document.title, window.location.href)
    })
  }, [])

  return (
    <div className={styles.root}>
      <div className={styles.bigSpacer} />
      <div
        style={{
          justifyContent: 'center',
          marginTop: 50,
          marginBottom: 30,
        }}
      >
        <Icon name="check circle" size="massive" />
      </div>

      <h2 className={styles.mainTitle}>Thank You!</h2>
      <h2 className={styles.mainTitle}>Your order has been placed.</h2>
      <div className={styles.bigSpacer} />
      <h4 className={styles.descriptionLabel}>
        We will send you an email confirmation with instructions regarding next
        steps.
      </h4>
      <div className={styles.spacer} />
      <h4 className={styles.descriptionLabel}>
        This order can now be found on the{' '}
        <Link className={styles.linkButton} to={LAB_REPORTS.base}>
          lab reports
        </Link>{' '}
        screen.
      </h4>
    </div>
  )
}
