import React from 'react'

import { Image } from 'semantic-ui-react'

import BoardApproved from '../../../assets/board-approved-logo.png'

import style from './ReviewBoardApproved.module.css'

export default function ReviewBoardApproved() {
  return (
    <a
      href="https://labsavvy.com/labsavvy-review-board/"
      target="_blank"
      rel="noreferrer noopener"
    >
      <div className={style.container} data-test="review-board-approved">
        <div className={style.imagesContainer}>
          <Image
            className={style.logo}
            alt="Review Board Approved"
            src={BoardApproved}
          />
        </div>
        <p className={style.content}>
          The content above has been thoroughly researched, produced, reviewed,
          and approved by the LabSavvy Review Board™. Our board is made up of
          top Medical Laboratory Scientists, subject matter experts, and
          clinicians from around the country.
        </p>
      </div>
    </a>
  )
}
