import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { Header } from '@labsavvyapp/ui-components'
import { useQuery } from '@apollo/client'
import { GetSession } from '../../graphql/user/queries'
import { isAuthenticated } from '../../utils/auth'
import { SIGN, HOME } from '../../routes/config'
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE } from '../../utils/externalLinks'
import logo from '../../assets//labsavvy-logo.svg'
import HeaderInfo from './HeaderInfo'
import style from './AuthLayout.module.css'

interface AuthLayoutProps {
  children: React.ReactElement
}

export default function AuthLayout({ children }: AuthLayoutProps) {
  const { push } = useHistory()
  const { data: sessionData } = useQuery(GetSession)
  const session = sessionData && sessionData.session
  const isLoggedIn = session ? session.isLoggedIn : isAuthenticated()

  return (
    <div className={style.root}>
      <Header
        className={style.header}
        logo={{ image: logo, url: isLoggedIn ? HOME : SIGN.in }}
        onLinkClick={() => push(isLoggedIn ? HOME : SIGN.in)}
      >
        <HeaderInfo />
      </Header>
      {children}
      <footer>
        <h5 className={style.footer}>
          ©{new Date().getFullYear()} All Rights Reserved. LabSavvy®, LabSavvy
          Health, LLC company.{' '}
          <Link
            className={style.footerLink}
            to={PRIVACY_POLICY_URL}
            target="_blank"
          >
            Privacy Policy
          </Link>
          {', and '}
          <Link
            className={style.footerLink}
            to={TERMS_OF_SERVICE}
            target="_blank"
          >
            Terms of Service
          </Link>
          .
        </h5>
      </footer>
    </div>
  )
}
