import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { ListChatMessages } from '../graphql/messaging/queries'
import { SendChatMessage } from '../graphql/messaging/mutations'

const mapFromWellnessTeamToFirebase = ({ to, sender, message, date }) => ({
  to,
  sender,
  text: message,
  timestamp: new Date(date).getTime(),
})

const mapFromFirebaseToWellnessTeam = ({ to, sender, text, timestamp }) => ({
  to,
  sender,
  message: text,
  date: new Date(timestamp),
})

const defaultOptions = {
  messaging: null,
  receiveMessage: null,
}

export const useMessageList = (messagingOptions = {}, queryOptions = {}) => {
  const options = { ...defaultOptions, ...messagingOptions }

  const [
    page,
    // setPage
  ] = useState(1)
  const [messages, setMessages] = useState([])
  const { data: messageListData, loading } = useQuery(ListChatMessages, {
    ...queryOptions,
    fetchPolicy: 'network-only',
    context: {
      clientName: 'messaging',
    },
    variables: {
      ...(queryOptions.variables || {}),
      page,
    },
    // TODO handle Safari fallback
    // const NEW_MESSAGES_POLL_INTERVAL = 5000
    // pollInterval: NEW_MESSAGES_POLL_INTERVAL
  })

  useEffect(() => {
    setMessages((prevMessages) => [
      ...prevMessages,
      ...[
        ...(messageListData?.listChatMessages?.messages || []).map((message) =>
          mapFromFirebaseToWellnessTeam(message),
        ),
      ].reverse(),
    ])
  }, [messageListData?.listChatMessages?.messages])

  const appendMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message])
  }

  // setup to sendMessage
  const [sendMessage] = useMutation(SendChatMessage, {
    context: {
      clientName: 'messaging',
    },
  })
  const sendMessageFn = (message) => {
    const messageObj = {
      ...message,
      date: new Date(),
    }
    appendMessage(messageObj)
    sendMessage({
      variables: mapFromWellnessTeamToFirebase(messageObj),
    })
  }

  // setup messaging to receiveMessage
  useEffect(() => {
    if (!options.messaging && !options.receiveMessage instanceof Function) {
      console.info('Wellness Team has no receiveMessage Handler defined')
      return
    }

    if (options.receiveMessage instanceof Function) {
      const receiveMessageFn = (message) => {
        const mappedMessage = mapFromFirebaseToWellnessTeam(message)
        appendMessage(mappedMessage)
        options.receiveMessage(mappedMessage)
      }

      const serviceWorkerOnMessage = (payload) => {
        const message = payload.data
        if (queryOptions.variables?.to == message.sender)
          receiveMessageFn(message)
      }
      const removeOnMessageListener = options.messaging.onMessage((payload) => {
        const message = payload.data
        if (queryOptions.variables?.to == message.sender)
          receiveMessageFn(message)
      })
      navigator.serviceWorker.addEventListener(
        'message',
        serviceWorkerOnMessage,
      )
      return () => {
        removeOnMessageListener()
        navigator.serviceWorker.removeEventListener(
          'message',
          serviceWorkerOnMessage,
        )
      }
    }
  }, [options.messaging, options.receiveMessages, queryOptions.variables?.to])

  return {
    loading,
    messages,
    sendMessage: sendMessageFn,
    resetMessages: () => {
      setMessages([])
    },
    page: messageListData?.listChatMessages?.page || 1,
    pages: messageListData?.listChatMessages?.pages || 0,
  }
}
