import { useState } from 'react'
import browserCookies from 'browser-cookies'

type ValueToSave = string | boolean | number | Record<string, unknown> | null
const SESSION_EXPIRATION = 0
// const A_DAY_EXPIRATION = 1
const EXPIRES = SESSION_EXPIRATION

export default function useLocalState(key: string, initialValue?: ValueToSave) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = browserCookies.get(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log('LocalState get error: ', error)
      return initialValue
    }
  })

  const setValueWrapper = (valueToSave: ValueToSave) => {
    try {
      setStoredValue(valueToSave)
      browserCookies.set(key, JSON.stringify(valueToSave), { expires: EXPIRES })
    } catch (error) {
      console.log('LocalState set error: ', error)
    }
  }

  return [storedValue, setValueWrapper]
}
