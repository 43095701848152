import gql from 'graphql-tag'

export const GetContactList = gql`
  query mobileListWellnessTeamMembers($userId: MongoID!) {
    mobileListWellnessTeamMembers(user_id: $userId) {
      id
      name {
        display
        first
        last
      }
      profile_photo {
        _id
        url
        versions {
          thumb
        }
      }
      job_title
      member_bio
      partner {
        _id
        name
      }
      revoked
    }
  }
`

export const MobileGetAvailableWellnessTeamMembersToAdd = gql`
  query mobileGetAvailableWellnessTeamMembersToAdd {
    mobileGetAvailableWellnessTeamMembersToAdd {
      partner {
        _id
        name
      }
      users {
        _id
        profile_photo {
          _id
          url
          versions {
            thumb
          }
        }
        name {
          display
          first
          last
        }
        job_title
        member_bio
      }
    }
  }
`

export const ListChatMessages = gql`
  query ListChatMessages(
    $to: MongoID!
    $page: NonNegativeInt = 1
    $limit: PositiveInt = 50
  ) {
    listChatMessages(
      to: $to
      page: $page
      limit: $limit
      sort: { timestamp: desc }
    ) {
      messages {
        sender
        to
        text
        timestamp
      }
      page
      pages
      total
    }
  }
`

export const MobileWellnessTeam = gql`
  query ($mobileUserId: MongoID!, $search: RegExp) {
    mobileWellnessTeam(
      input: { filter: { user_id: $mobileUserId, search: $search } }
    ) {
      page
      pages
      total
      wellnessTeam: data {
        partner {
          _id
          name
        }
        available {
          _id
          name {
            first
            last
            display
          }
          academic_title
          member_bio
          job_title
          revoked
        }
        connected {
          _id
          name {
            first
            last
            display
          }
          academic_title
          member_bio
          job_title
          revoked
        }
      }
    }
  }
`
