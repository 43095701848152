const dateIsoUtcString = (date: string) => {
  const dateObj = new Date(date)
  const year = dateObj.getFullYear()
  const month = dateObj.getMonth()
  const day = dateObj.getDate()
  const dateForApi = new Date(Date.UTC(year, month, day))

  return dateForApi.toISOString()
}

export default dateIsoUtcString
