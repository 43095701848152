import React from 'react'
import { Switch, Route } from 'react-router'
import { useQuery } from '@apollo/client'

import { PROFILE } from './config'
import ProfileScreen from '../pages/profile/ProfileScreen'
import Step1 from '../pages/profile/ChangePassword/Step1'
import Step2 from '../pages/profile/ChangePassword/Step2'
import Step3 from '../pages/profile/ChangePassword/Step3'
import { GetProfile } from '../graphql/user/queries'
import withMainLayout from '../layouts/Main/withMainLayout'

interface ProfileRoutesProps {
  path: string
  loggedIn: boolean
}

export default function ProfileRoutes({ path, loggedIn }: ProfileRoutesProps) {
  const { data: profileData } = useQuery(GetProfile)
  const me = profileData && profileData.mobileGetMe
  return (
    <Route
      path={path}
      render={() => (
        <Switch>
          <Route
            exact
            path={PROFILE.base}
            render={() =>
              withMainLayout(ProfileScreen, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={PROFILE.edit}
            render={() =>
              withMainLayout(ProfileScreen, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={PROFILE.email}
            render={() =>
              withMainLayout(Step1, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={PROFILE.confirmToken}
            render={() =>
              withMainLayout(Step2, {
                me,
                loggedIn,
              })
            }
          />
          <Route
            exact
            path={PROFILE.changePassword}
            render={() =>
              withMainLayout(Step3, {
                me,
                loggedIn,
              })
            }
          />
        </Switch>
      )}
    />
  )
}
