import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faAngleLeft,
  faPlus,
  faUser,
  faEnvelope,
  faGift,
  faTransgender,
  faTimes,
} from '@fortawesome/pro-light-svg-icons'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'

interface IconProps {
  className?: string
  color?: string
  size?: SizeProp
}

export const BackwardIcon = ({ className, size, color }: IconProps) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faAngleLeft}
    size={size}
  />
)
export const ForwardIcon = ({ className, size, color }: IconProps) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faAngleRight}
    size={size}
  />
)
export const PlusIcon = ({ className, size, color }: IconProps) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faPlus}
    size={size}
  />
)
export const UserIcon = ({ className, size, color }: IconProps) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faUser}
    size={size}
  />
)
export const EnvelopeIcon = ({ className, size, color }: IconProps) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faEnvelope}
    size={size}
  />
)
export const BirthIcon = ({ className, size, color }: IconProps) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faEnvelope}
    size={size}
  />
)
export const GiftIcon = ({ className, size, color }: IconProps) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faGift}
    size={size}
  />
)
export const GenderIcon = ({ className, size, color }: IconProps) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faTransgender}
    size={size}
  />
)
export const CrossIcon = ({ className, size, color }: IconProps) => (
  <FontAwesomeIcon
    className={className}
    color={color}
    icon={faTimes}
    size={size}
  />
)
