import React from 'react'
import { Redirect, Switch, Route } from 'react-router'
import { useApolloClient, useQuery } from '@apollo/client'

import { InfiniteScrollListProvider } from '@labsavvyapp/ui-components'

import {
  HOME,
  SIGN,
  LAB_REPORTS,
  SETTINGS,
  PROFILE,
  // SHOP,
  EASY_LAB_ACCESS,
  STORE,
  WELLNESS_TEAM,
} from './config'
import AuthRoutes from './AuthRoutes'
import LabReportsRoutes from './LabReportsRoutes'
import { isAuthenticated, removeCookies } from '../utils/auth'
import { GetSession, GetMe } from '../graphql/user/queries'
// import ShopRoutes from './ShopRoutes'
import SettingsRoutes from './SettingsRoutes'
import ProfileRoutes from './ProfileRoutes'
import withMainLayout from '../layouts/Main/withMainLayout'
import Messaging from '../pages/Messaging/Messaging'
import StoreRoutes from './StoreRoutes'
import EasyLabAccessRoutes from './EasyLabAccessRoutes'

export interface User {
  _id: string
  UserName: string
  capabilities: {
    canReleaseResults: boolean
    isAccountOwner: boolean
    isReadOnlyPartnerManager: boolean
    isSuperAdmin: boolean
  }
  name: {
    display: string
    first: string
    last: string
  }
  partner: {
    _id: string
    has_stripe_account: boolean
  }
  project: {
    _id: string
    consumers_title: string
  }
}

const Routes = () => {
  const client = useApolloClient()

  const { data: sessionData } = useQuery(GetSession)
  const session = sessionData && sessionData.session
  const isLoggedIn = session ? session.isLoggedIn : isAuthenticated()
  const { data: userData, error } = useQuery(GetMe, { skip: !isLoggedIn })
  const user: User = userData && userData.mobileGetMe
  const hasAuthenticationError =
    error &&
    error.graphQLErrors.find(
      ({ message }) => message === 'Unauthorized Access.',
    )

  if (hasAuthenticationError) {
    removeCookies()
    client.resetStore()
  }

  return (
    <Switch>
      <AuthRoutes path={SIGN.base} />

      <EasyLabAccessRoutes path={EASY_LAB_ACCESS.base} />

      {isLoggedIn && !hasAuthenticationError ? (
        <InfiniteScrollListProvider>
          <Switch>
            <Route exact path="/">
              <Redirect to={HOME} />
            </Route>

            {/* <ShopRoutes path={SHOP.base} loggedIn={isLoggedIn} me={user} /> */}

            <StoreRoutes path={STORE.base} loggedIn={isLoggedIn} me={user} />

            <LabReportsRoutes
              path={LAB_REPORTS.base}
              me={user}
              loggedIn={isLoggedIn}
            />

            <SettingsRoutes
              path={SETTINGS.base}
              me={user}
              loggedIn={isLoggedIn}
            />
            <ProfileRoutes path={PROFILE.base} loggedIn={isLoggedIn} />
            <Route
              exact
              path={[WELLNESS_TEAM.base, WELLNESS_TEAM.chat]}
              render={() =>
                withMainLayout(Messaging, {
                  me: user,
                  loggedIn: isLoggedIn,
                })
              }
            />
          </Switch>
        </InfiniteScrollListProvider>
      ) : (
        <Redirect to={SIGN.in} />
      )}
    </Switch>
  )
}

export default Routes
