import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { ListMenu } from '@labsavvyapp/ui-components'

import { SETTINGS } from '../../routes/config'
import styles from './SettingsLayout.module.css'
import { useEffect } from 'react'

interface IRoutes {
  text: string
  value: string
}
interface ISettingsLayoutProps {
  children: React.ReactElement
}

const ROUTES: Array<IRoutes> = [
  { value: SETTINGS.connectedProviders, text: 'Connected Providers' },
  { value: SETTINGS.account, text: 'Account' },
  { value: SETTINGS.info, text: 'Information' },
]

const SettingsLayout = ({ children }: ISettingsLayoutProps) => {
  const { push } = useHistory()
  const [selected, setSelected] = useState(SETTINGS.connectedProviders)

  useEffect(() => {
    push(selected)
  }, [])

  const handleClick = ({ value }: IRoutes) => {
    setSelected(value)
    push(value)
  }

  return (
    <main className={styles.main}>
      <div className={styles.header}>
        <h1 className={styles.pageTitle}>Settings & Info</h1>
      </div>
      <div className={styles.content}>
        <ListMenu
          items={ROUTES}
          onClick={handleClick}
          selectedItem={selected}
        />
        {children}
      </div>
    </main>
  )
}

export default SettingsLayout
