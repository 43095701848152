import React from 'react'
import style from './CheckboxRound.module.css'
import { Link } from 'react-router-dom'
import { HIPAA_AUTH_URL, TERMS_OF_SERVICE } from '../../utils/externalLinks'
interface CheckboxRoundProps {
  handleChange: (event: any) => void
}

export default function CheckboxRound({ handleChange }: CheckboxRoundProps) {
  return (
    <div className={style.container}>
      <div className={style.round}>
        <input
          onChange={(event) => handleChange(event.target.checked)}
          type="checkbox"
          id="checkbox"
        />
        <label htmlFor="checkbox"></label>
      </div>
      <p>
        {'I have read and accept our '}
        <Link className={style.link} to={HIPAA_AUTH_URL} target="_blank">
          HIPAA Authorization
        </Link>
        {' and '}
        <Link className={style.link} to={TERMS_OF_SERVICE} target="_blank">
          Terms of Service
        </Link>
      </p>
    </div>
  )
}
