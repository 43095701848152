import browserCookies from 'browser-cookies'
import { decode } from 'jsonwebtoken'

interface JwtProperties {
  exp: number
}

type JwtPayload = JwtProperties | null

export const setCookie = (token: string) => {
  const jwtPayload: JwtPayload = decode(token) as JwtPayload

  if (jwtPayload) {
    sessionStorage.setItem('login', 'true')
    browserCookies.set('lsat', token, {
      expires: new Date(jwtPayload.exp * 1000),
    })
  }
}

const getCookie = (name: string) => {
  const cookie = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)
  return cookie ? cookie[2] : null
}

export const removeCookies = () => {
  browserCookies.erase('lsat')
  browserCookies.erase('shop_bag')
  sessionStorage.clear()
}

const isValidToken = (token: string) => {
  const jwtPayload = decode(token)
  return (jwtPayload as any).exp > Math.round(Date.now() / 1000)
}

export const isAuthenticated = () => {
  const cookie = getCookie('lsat')
  return cookie && isValidToken(cookie)
}
