import { useQuery } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { Placeholder } from 'semantic-ui-react'

import { format } from 'date-fns'
import { Link, generatePath } from 'react-router-dom'
import { ListRow } from '@labsavvyapp/ui-components'
import classnames from 'classnames'
import { MobileGetLabOrder } from '../../../graphql/labOrders/queries'
import LabReportSummary from './LabReportSummary'
import style from './LabReportsListPage.module.css'
import { LAB_REPORTS } from '../../../routes/config'
import capitalize from '../../../utils/capitalize'
import { MarkResultAsViewed } from '../../../graphql/labOrders/mutations'

const statuses = Object.freeze({
  ORDERED: 'ordered',
  FINAL: 'final',
})

export interface LabReportData {
  id: string
  shortId: string
  name: string
  orderedAt: Date
  status: string
  partner: {
    name: string
  }
  provider: {
    _id(_id: any): string
    name: string
    urlAppointment: string
  }
  summary: {
    normal: number
    abnormal: number
  }
}

interface LabReportRowProps {
  data: LabReportData
}

export default function LabReportRow({ data }: LabReportRowProps) {
  const {
    id: labReportId,
    shortId,
    name,
    orderedAt,
    partner,
    provider,
    status,
    summary: { normal, abnormal },
  } = data

  // Fetch Lab Order
  const { data: labOrderData, loading: labOrderLoading } = useQuery(
    MobileGetLabOrder,
    {
      variables: { id: labReportId },
    },
  )

  const [markResultAsViewed] = useMutation(MarkResultAsViewed)

  const onClickHandler = () => {
    if (status === statuses.ORDERED) return

    markResultAsViewed({
      variables: {
        id: labReportId,
      },
    })
  }

  return (
    <>
      <ListRow
        className={classnames(style.listRow, {
          [style.listRowFinal]: status === statuses.FINAL,
          [style.listRowOrdered]: status === statuses.ORDERED,
        })}
        as={status === statuses.FINAL ? Link : 'div'}
        to={{
          pathname: generatePath(LAB_REPORTS.view, { labReportId }),
          state: { prevPath: window.location.pathname },
        }}
        onClick={onClickHandler}
        bordered={false}
      >
        <span className={style.reportName}>{name}</span>
        <span className={style.text}>Lab: {provider?.name}</span>
        <span className={style.text}>
          {orderedAt && format(new Date(orderedAt), 'MMM d, yyyy')}
        </span>
        {status === statuses.ORDERED ? (
          <span className={style.text}>
            <a
              href={data.provider.urlAppointment}
              target="_blank"
              rel="noreferrer"
            >
              Schedule Appointment
            </a>
          </span>
        ) : (
          <LabReportSummary normal={normal} abnormal={abnormal} />
        )}

        <span className={style.reportID}>Order ID:{shortId}</span>
        <span className={style.text}>Provider: {partner?.name}</span>
        <span className={style.text}>
          Status: {capitalize(status).replace(/_/g, ' ')}
        </span>
        {status === statuses.ORDERED &&
        labOrderData?.mobileGetLabOrder?.order_documents[0]?.url ? (
          <span className={style.text}>
            <a
              href={labOrderData?.mobileGetLabOrder?.order_documents[0]?.url}
              target="_blank"
              rel="noreferrer"
            >
              Print Lab Order
            </a>
          </span>
        ) : (
          labOrderLoading && (
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          )
        )}
      </ListRow>
    </>
  )
}
