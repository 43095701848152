import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'

import {
  PasswordStepForm,
  ChangePasswordStep,
  useSavingModals,
} from '@labsavvyapp/ui-components'

import { RecoverEmail } from '../../../graphql/user/mutations'
import style from './ChangePassword.module.css'
import { SIGN } from '../../../routes/config'

interface FormPreRegister {
  confirmPassword: string
}

interface IProps {
  me: any
}

function RecoverNewPassword({}: IProps) {
  const { push } = useHistory()
  const { search } = useLocation()
  const [changePassword] = useMutation(RecoverEmail)
  const intl = useIntl()

  const queryParams = new URLSearchParams(search)
  const email = queryParams.get('email')
  const proof = queryParams.get('proof')

  let nextPath = ''

  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're processing",
    savedMessage: 'Password saved successfully',
    errorMessage: 'Error changing password',
    callback: (data: FormPreRegister) => {
      nextPath = `${SIGN.in}`
      return changePassword({
        variables: {
          proof: proof,
          email: email,
          newPassword: data.confirmPassword,
        },
      })
    },
    onSuccess: () => push(nextPath),
  })

  return (
    <main className={style.main}>
      <div className={style.form}>
        <PasswordStepForm
          type="change"
          onSubmit={showModals}
          currentStep="3"
          totalSteps="3"
          intl={intl}
          title="Choose a Password"
          submitButtonText="Save Password"
        >
          <div style={{ textAlign: 'left', marginBottom: 30, fontWeight: 200 }}>
            <p>Please enter the new password</p>
            <ul>
              <li>8 to 12 characters in length</li>
              <li>at least 1 Capital letter</li>
              <li>at least 1 Lowercase letter</li>
              <li>at least 1 Number, and</li>
              <li>at least 1 Special character</li>
            </ul>
          </div>
          <ChangePasswordStep />
        </PasswordStepForm>
      </div>
      {modals}
    </main>
  )
}

export default RecoverNewPassword
