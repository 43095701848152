export const PRIVACY_POLICY_URL = {
  pathname: 'https://labsavvy.com/privacy-policy/',
}
export const TERMS_OF_SERVICE = {
  pathname: 'https://labsavvy.com/terms-of-service/',
}

export const HIPAA_AUTH_URL = {
  pathname: 'https://labsavvy.com/hipaa-authorization/',
}

export const RETURNS_URL = {
  pathname: 'https://labsavvy.com/cancelations-and-returns/',
}
