import React from 'react'
import get from 'lodash/get'
import classnames from 'classnames'
import { format } from 'date-fns'

import style from './ReportDetails.module.css'
import FancyID from '../../utils/FancyID'
import capitalize from '../../utils/capitalize'
import PDFLink from '../pdflink/PDFLink'

export interface LabReportData {
  id: string
  shortId: string
  name: string
  orderedAt: Date
  collectedAt: Date
  reportedAt: Date
  status: string
  provider: {
    name: string
  }
  summary: {
    normal: number
    abnormal: number
  }
}

interface ReportDetailsProps {
  labReport: LabReportData
  url: string
}

export default function ReportDetails({ labReport, url }: ReportDetailsProps) {
  if (!labReport) return null

  const formatDate = (date: Date) => {
    return date ? format(new Date(date), 'MMM d, yyyy') : ''
  }

  return (
    <section className={style.container} data-test="lab-report-profile">
      <h1 className={style.name}>{labReport.name}</h1>
      <div className={style.detailsItem}>
        <span>
          Order ID: <FancyID id={labReport.shortId} />
        </span>
      </div>
      {labReport.summary && (
        <div className={style.summary}>
          <div className={classnames(style.summaryValue, style.summaryNormal)}>
            {get(labReport, 'summary.normal', 0)}
          </div>
          <div
            className={classnames(style.summaryValue, style.summaryAbnormal)}
          >
            {get(labReport, 'summary.abnormal', 0)}
          </div>
        </div>
      )}
      <div className={style.detailsItem}>
        <span>
          <span style={{ fontSize: 14 }}>Lab: </span>
          {get(labReport, 'provider.name', '')}
        </span>
        <span>
          <span style={{ fontSize: 14 }}>Status: </span>
          {capitalize(labReport.status).replace(/_/g, ' ')}
        </span>
        <span>
          <span style={{ fontSize: 14 }}>Ordered: </span>
          {formatDate(labReport.orderedAt)}
        </span>
        <span>
          <span style={{ fontSize: 14 }}>Collected: </span>
          {formatDate(labReport.collectedAt)}
        </span>
        <span>
          <span style={{ fontSize: 14 }}>Reported: </span>{' '}
          {formatDate(labReport.reportedAt)}
        </span>

        <PDFLink url={url} labReportId={labReport.id}>
          {'View Official Lab Report'}
        </PDFLink>
      </div>
    </section>
  )
}
