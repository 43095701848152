import { gql } from '@apollo/client'

export const ListLabOrders = gql`
  query ListLabOrders($labOrderInput: LabOrdersPageInput) {
    labOrders(input: $labOrderInput) {
      page
      pages
      total
      lab_orders {
        ... on ClientLabOrder {
          _id
          short_id
          name
          ordered_at
          status
          summary {
            normal
            abnormal
          }
          partner {
            name
          }
          provider {
            name
            _id
            url_appointment
          }
        }
      }
    }
  }
`

export const ListLabOrdersDetails = gql`
  query ListLabOrders(
    $labOrderInput: LabOrdersPageInput
    $testsInput: TestsInput
  ) {
    labOrders(input: $labOrderInput) {
      page
      pages
      total
      lab_orders {
        ... on ClientLabOrder {
          _id
          short_id
          name
          collected_at
          reported_at
          ordered_at
          status
          date
          result_documents {
            order_short_id
            results_pdf_report {
              url
            }
          }
          summary {
            normal
            abnormal
            neutral
          }
          partner {
            name
          }
          notes
          provider {
            name
          }
          order_documents(
            input: { filter: { type: [ReqFormPdf, AbnFormPdf] } }
          ) {
            ... on ReqFormPdf {
              url
              short_id
            }
            ... on AbnFormPdf {
              url
              short_id
            }
            ... on AoeUrl {
              url
              short_id
            }
          }
          results {
            name
            notes
            details
            summary
            subtitle
            icon {
              url
            }
            tests(input: $testsInput) {
              _id
              name
              value
              units
              status
              indicator
              notes
              ref_range
              flag_statement
              aka
              external_references {
                ad_hoc {
                  url
                  title
                }
                wikipedia {
                  url
                  logo {
                    url
                  }
                }
                medline {
                  url
                  logo {
                    url
                  }
                }
              }
              result_summaries {
                what_is_this_test_used_for
                what_might_affect_test_results
                what_should_i_ask_my_clinician
                test_summaries {
                  question
                  answer
                }
              }
              test_history {
                ranges {
                  max
                  min
                  type
                  __typename
                }
                results {
                  date
                  tooltip
                  value
                  type
                  __typename
                }
                units
              }
            }
          }
        }
      }
    }
  }
`

export const MobileGetLabOrder = gql`
  query MobileGetLabOrder($id: MongoID!) {
    mobileGetLabOrder(id: $id) {
      order_documents {
        ... on ReqFormPdf {
          url
          short_id
        }
        ... on AbnFormPdf {
          url
          short_id
        }
        ... on AoeUrl {
          url
          short_id
        }
      }
      result_documents_base64 {
        last_update
        pdf_base64
      }
    }
  }
`
