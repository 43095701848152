import gql from 'graphql-tag'

export const SendChatMessage = gql`
  mutation SendChatMessage($to: ID!, $text: String!, $timestamp: DateTime!) {
    sendChatMessage(to: $to, text: $text, timestamp: $timestamp)
  }
`
export const AddWellnessTeamMember = gql`
  mutation mobileAddWellnessTeamMember($memberToAdd: MongoID!) {
    mobileAddWellnessTeamMember(memberToAdd: $memberToAdd)
  }
`

export const RevokeWellnessTeamMember = gql`
  mutation mobileRevokeWellnessTeamMembers($membersToRevoke: [MongoID!]!) {
    mobileRevokeWellnessTeamMembers(membersToRevoke: $membersToRevoke) {
      _id
    }
  }
`

export const StoreRegistrationToken = gql`
  mutation StoreRegistrationToken($token: String!) {
    storeRegistrationToken(registrationToken: $token)
  }
`
