/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Button, ErrorModal } from '@labsavvyapp/ui-components'
import { useHistory } from 'react-router'
import { Icon } from 'semantic-ui-react'
import { CrossIcon } from '../../../components/Icons/light'
import { GetCardList } from '../../../graphql/ecommerce/queries'
import { RemoveCreditCard } from '../../../graphql/ecommerce/mutation'
import { Card } from '../../../components/context/ShopContext'
import { STORE } from '../../../routes/config'
import styles from './PaymentMethodPage.module.css'

export default function PaymentMethodPage() {
  const [selSourceId, setSourceId] = useState(null)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('error')
  const [card, setCard] = useState<Card | null>(null)
  const { data } = useQuery(GetCardList, {
    fetchPolicy: 'no-cache',
  })
  const [deleteCard, { loading: deleteLoading }] = useMutation(
    RemoveCreditCard,
    {
      onCompleted: () => {
        setCard(null)
      },
      onError: (error) => {
        setErrorMessage(
          error.message,
        )
        setShowErrorModal(true)
      },
    },
  )
  
  
  const { push } = useHistory()

  const goToPayment = () => {
    push(`${STORE.payment}`)
  }

  const onDeleteCard = async (srcId) => {
    if(srcId){
      setSourceId(srcId)
      await deleteCard({ variables: { sourceId: srcId } })
    }else{
      setErrorMessage(
        `Cannot delete Payment Method. Please contact the admin`,
      )
      setShowErrorModal(true)
    }
  }

  useEffect(() => {
    if (data && data.mobileListPaymentMethods) {
      const defaultCard = data.mobileListPaymentMethods.filter(
        (card: Card) => card.default,
      )

      if (defaultCard.length > 0) {
        setCard(defaultCard)
      }
    }
  }, [data])

  const paymentButtonText = card
    ? 'Update Payment Method'
    : 'Add Payment Method'

  return (
    <div className={styles.container}>
      <ErrorModal
        open={showErrorModal}
        message={errorMessage}
        onCloseClick={() => {
          setShowErrorModal(false)
        }}
      />
      {card
        ? card.map((crdlst, key) => {
            return (
              <div key={key} className={styles.cardInfoContainer}>
                <h5 className={styles.paymentCardInfo}>
                  {crdlst.brand}
                  {' ****'}
                  {crdlst.last4}
                </h5>
                {!deleteLoading ? (
                  <div onClick={() => onDeleteCard(crdlst?.id)}>
                    <CrossIcon className={styles.icon} size="lg" />
                  </div>
                ) : null}
                {deleteLoading ? (
                  <Icon name="spinner" loading size="small" />
                ) : null}
              </div>
            )
          })
        : null}
      <Button
        className={styles.paymentButton}
        variant={Button.variant.basic}
        onClick={goToPayment}
      >
        <h5 className={styles.paymentButtonLabel}>{paymentButtonText}</h5>
      </Button>
    </div>
  )
}
/* eslint-enable */
