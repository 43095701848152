import React from 'react'
import { Test } from '../ResultDetails'
// import ExternalReferenceItem from './ExternalReferenceItem'
import style from './ExternalReferences.module.css'

// const EXTERNAL_REFERENCES_ORDER: any = {
//   medline: 0,
//   wikipedia: 1,
// }

export interface ExternalReference {
  url: string
  logo: {
    url: string
  }
}

export interface IExternalReferences {
  wikipedia: ExternalReference
  medline: ExternalReference
}

interface ExternalReferencesProps {
  externalReferences: IExternalReferences
  result: Test
}

export default function ExternalReferences({
  externalReferences,
  result,
}: ExternalReferencesProps) {
  if (!externalReferences || !Object.keys(externalReferences).length)
    return null

  return (
    // (hasExternalReferences(externalReferences) && (
    <div className={style.container}>
      <div>
        <h2 className={style.title}>External Resources</h2>
      </div>

      <div className={style.subTitle}>
        Find more information about <b>{result && result.name}</b> from the
        following sources:
      </div>

      {externalReferences.map((externalReference, key) => {
        return (
          <div
            key={key}
            style={{ marginLeft: 20, marginTop: 20, marginBottom: 20 }}
          >
            <b style={{ fontSize: 16, marginRight: 10 }}>
              {externalReference.title}
            </b>
            <br></br>
            <a
              href={externalReference.url}
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: 14 }}
            >
              {externalReference.url}
            </a>
          </div>
        )
      })}
    </div>
  )
}
