import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { useMutation } from '@apollo/client'
import {
  ErrorModal,
  SavedModal,
  PasswordStepForm,
  ChangePasswordStep,
} from '@labsavvyapp/ui-components'
import style from './SignUpPage.module.css'
import { RegisterUser } from '../../../graphql/user/mutations'
import { SIGN } from '../../../routes/config'
interface FormPasswordCreationScreen {
  password: string
  confirmPassword: string
}

function PasswordCreationScreen() {
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSavedModal, setShowSavedModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('error')
  const [registerUser] = useMutation(RegisterUser)

  const onSubmit = async ({ password }: FormPasswordCreationScreen) => {
    try {
      const userJson = JSON.parse(localStorage.getItem('newUser') || '{}')
      const partnerCode = JSON.parse(localStorage.getItem('partnerCode'))

      await registerUser({
        variables: {
          ...userJson,
          password: password,
          partnerCode: partnerCode,
        },
      })

      setShowSavedModal(true)
      setTimeout(() => {
        push(SIGN.in)
      }, 2100)
    } catch (error) {
      // console.log('error!: ', error)
      setErrorMessage(error?.graphQLErrors[0]?.message)
      setShowErrorModal(true)
    }
  }

  return (
    <div className={style.root}>
      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
        duration={2000}
      />
      <SavedModal
        open={showSavedModal}
        message="User account saved! Redirecting you to the sign in page."
        onClose={() => setShowSavedModal(false)}
      />
      <div className={style.formContainer}>
        <PasswordStepForm
          hasLastStepSubmit={false}
          onSubmit={onSubmit}
          currentStep="5"
          totalSteps="5"
          intl={{
            formatMessage,
          }}
          title="Choose a Password"
          submitButtonText="Save Password"
        >
          <div style={{ textAlign: 'left', marginBottom: 30, fontWeight: 200 }}>
            <p>Please enter the new password</p>
            <ul>
              <li>8 to 12 characters in length</li>
              <li>at least 1 Capital letter</li>
              <li>at least 1 Lowercase letter</li>
              <li>at least 1 Number, and</li>
              <li>at least 1 Special character</li>
            </ul>
          </div>
          <ChangePasswordStep />
        </PasswordStepForm>
      </div>
    </div>
  )
}

export default PasswordCreationScreen
