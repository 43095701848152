import React from 'react'

import style from './DetailsLayout.module.css'
import Header from './Header'
import Panel from './Panel'
import Nav from './Nav'
import Footer from './Footer'

interface DetailsLayoutProps {
  children: any
}

export const DetailsLayout = ({ children }: DetailsLayoutProps) => {
  const { header, nav, panels, footer } = children.reduce(
    (filteredChildren: any, currentChildren: any) => {
      const { ...newChildren } = filteredChildren

      switch (currentChildren.type.layoutElementName) {
        case 'Header': {
          return { ...newChildren, header: currentChildren }
        }

        case 'Nav': {
          return { ...newChildren, nav: currentChildren }
        }

        case 'Panel': {
          if (Array.isArray(newChildren.panels)) {
            return {
              ...newChildren,
              panels: [...newChildren.panels, currentChildren],
            }
          } else {
            return {
              ...newChildren,
              panels: [currentChildren],
            }
          }
        }

        case 'Footer': {
          return { ...newChildren, footer: currentChildren }
        }

        default: {
          return newChildren
        }
      }
    },
    {},
  )

  return (
    <div className={style.container}>
      {header}

      {nav}

      <div className={style.panels}>{panels}</div>

      {footer}
    </div>
  )
}

DetailsLayout.Header = Header
DetailsLayout.Panel = Panel
DetailsLayout.Nav = Nav
DetailsLayout.Footer = Footer
