import React from 'react'
import { useQuery } from '@apollo/client'
import { MobileGetLabOrder } from '../../graphql/labOrders/queries'

import style from './PDFLink.module.css'

interface PDFLinkProps {
  children: string
  url: string
  labReportId: string
}

export default function PDFLink({ url, children, labReportId }: PDFLinkProps) {
  // Fetch Lab Order
  const { data: labOrderData } = useQuery(MobileGetLabOrder, {
    variables: { id: labReportId },
  })

  const showQuestResults = (data: any) => {
    const byteCharacters = atob(data)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const file = new Blob([byteArray], { type: 'application/pdf;base64' })
    const fileURL = URL.createObjectURL(file)
    window.open(fileURL)
  }

  return (
    <>
      {!labOrderData?.mobileGetLabOrder?.result_documents_base64?.pdf_base64 ? (
        <a
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          className={style.resultReportLink}
        >
          {children}
        </a>
      ) : (
        <a
          className={style.resultReportLink}
          onClick={() =>
            showQuestResults(
              labOrderData?.mobileGetLabOrder?.result_documents_base64
                ?.pdf_base64,
            )
          }
        >
          {children}
        </a>
      )}
    </>
  )
}
