import '@labsavvyapp/ui-components/lib/theme/theme-variables.css'
import '@labsavvyapp/ui-components/lib/theme/theme.css'
import '@labsavvyapp/ui-components/lib/index.css'

import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import * as Sentry from '@sentry/browser'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ShopProvider } from './components/context/ShopContext'
import HTMLHeaders from './HTMLHeaders'
import Routes from './routes/Routes'
import { createClient } from './graphql/graphqlClient'
import './styles/app.css'
import { defaultLocale, translations } from './locales'
import IdleTimerContainer from './utils/idle/IdleTimeContainer'
import { ThemeProvider } from '@labsavvyapp/components'

if (process.env.REACT_APP_USE_ERROR_TRACKING === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
  })
}

function App() {
  return (
    <div className="App">
      <ApolloProvider client={createClient()}>
        <ShopProvider>
          <BrowserRouter>
            <IntlProvider
              locale={defaultLocale}
              messages={translations[defaultLocale]}
            >
              <HelmetProvider>
                <ThemeProvider>
                  <HTMLHeaders />
                  <Routes />
                  <IdleTimerContainer />
                </ThemeProvider>
              </HelmetProvider>
            </IntlProvider>
          </BrowserRouter>
        </ShopProvider>
      </ApolloProvider>
    </div>
  )
}

export default App
