import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { HttpLink } from 'apollo-link-http'
import { RetryLink } from 'apollo-link-retry'
import fetch from 'node-fetch'
import browserCookies from 'browser-cookies'

const LAB_SAVVY_GRAPHQL_URL = `${process.env.REACT_APP_BACKEND_URL}/graphql`

const DEFAULT_HEADERS = {
  'ls-channel': 'mobile',
}

function createMiddlewareLink(token) {
  // Headers
  //   if (global.Headers == null) {
  //     global.Headers = fetch.Headers
  //   }

  return new ApolloLink((operation, forward) => {
    let authorizationHeader
    if (typeof window === 'object') {
      const browserToken = browserCookies.get('lsat')
      authorizationHeader = browserToken ? `JWT ${browserToken}` : null
    } else {
      authorizationHeader = token ? `JWT ${token}` : null
    }

    operation.setContext({
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: authorizationHeader,
      },
    })

    // Clean __typename
    // We don't want to remove the typename for when a file is sent,
    // it will break the upload.
    if (operation.variables && !operation.variables.file) {
      const omitTypename = (key, value) =>
        key === '__typename' ? undefined : value

      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename,
      )
    }

    return forward(operation)
  })
}

function createHTTPLink(token) {
  const middlewareLink = createMiddlewareLink(token)

  const labSavvyLink = new HttpLink({
    uri: LAB_SAVVY_GRAPHQL_URL,
    fetch,
  })
  const messagingLink = new HttpLink({
    uri: process.env.REACT_APP_FIREBASE_GRAPHQL_URL,
    fetch,
  })

  const uploadLink = createUploadLink({
    uri: LAB_SAVVY_GRAPHQL_URL,
    fetch,
  })

  return new RetryLink().split(
    (operation) => operation.getContext().clientName === 'messaging',
    middlewareLink.concat(messagingLink),
    middlewareLink.concat(uploadLink, labSavvyLink),
  )
}

export function createClient() {
  const client = new ApolloClient({
    link: createHTTPLink(),
    cache: new InMemoryCache(),
    resolvers: {},
  })

  return client
}
