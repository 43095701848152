import React from 'react'
import { useHistory } from 'react-router'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { Icon, Popup } from 'semantic-ui-react'
import { generatePath } from 'react-router-dom'

import { ListTransactions } from '../../../graphql/ecommerce/queries'

import style from './TransactionsScreen.module.css'
import { LAB_REPORTS } from '../../../routes/config'
import formatterPrice from '../../../utils/formatter-price'

interface ITransaction {
  payment_intent_id: string
  products: []
  amount: number
  date: Date
}

const TransactionsScreen = () => {
  const { push } = useHistory()
  const { data } = useQuery(ListTransactions)

  let transactions: ITransaction[] = []
  if (data) {
    transactions = data.mobileListTransactions.transactions
  }

  return (
    <>
      <div className={style.transactionsContainer}>
        {transactions.length === 0 && <div>No transactions</div>}
        {transactions.map((transaction: ITransaction, index: number) => {
          const labReportId = transaction.lab_order_id
          return (
            <div
              className={style.transactionItem}
              key={transaction.payment_intent_id || index}
            >
              <div className={style.transactionItemColumn}>
                <div className={style.transactionName}>
                  {transaction.lab_report_name}
                  {['partial', 'final'].includes(
                    transaction.lab_order_status,
                  ) && (
                    <Popup
                      trigger={
                        <a
                          onClick={() => {
                            push(
                              generatePath(LAB_REPORTS.view, { labReportId }),
                            )
                          }}
                        >
                          <Icon
                            className={style.transactionLink}
                            link
                            name="arrow right"
                            color="purple"
                          />
                        </a>
                      }
                      content="Go to Lab Report"
                      position="right center"
                    />
                  )}
                  <div style={{ fontSize: 10, fontWeight: 400 }}>
                    Order ID: {transaction.lab_order_id}
                  </div>
                </div>
              </div>
              <div className={style.transactionItemColumn}>
                <div className={style.transactionSubtext}>
                  Lab: {transaction.provider_name}
                </div>
                <div className={style.transactionSubtext}>
                  Partner: {transaction.partner_name}
                </div>
              </div>
              <div className={style.priceColumn}>
                <div className={style.transactionDate}>
                  {transaction.date
                    ? format(new Date(transaction.date), 'MMMM d, yyyy')
                    : ''}
                </div>
                <div className={style.transactionPrice}>
                  {formatterPrice(transaction.amount)}
                </div>
              </div>
              {transaction?.products.map((product: string, index: number) => {
                return <div key={product + index}>{product}</div>
              })}
            </div>
          )
        })}
      </div>
    </>
  )
}

export default TransactionsScreen
